import { userInfo } from "os";
import { useFetchUser } from "./hooks/useFetchUser";
import { PartialGuild, User } from "./types";



export const getIconURL = (guild: PartialGuild) => `https://cdn.discordapp.com/icons/${guild.id}/${guild.icon}.png`;

export const getUserIconURL = (user: User) => `https://cdn.discordapp.com/avatars/${user.discord_id}/${user.avatar}.png`;


export const getDefaultUserIcon = (user: User) => `https://cdn.discordapp.com/embed/avatars/${Number(user.discriminator) % 5}.png `
