import axios from "axios";
import html2canvas from "html2canvas";
import { useContext, useRef, useState } from "react";
import { Col, Row } from "react-grid-system";
import { BarLoader } from "react-spinners";
import { GridContainer } from "../Types/types";
import {
  formatDate,
  formatLongDate,
  weeklyDate,
} from "../components/EarningsReportData";
import { UserContext } from "../utils/context/UserContext";
import { useNewEarningsData } from "../utils/hooks/useEarningsData";
import { Button, Flex, ImageText, StockImage } from "../utils/styles";
const NullStock = require("../assets/GrayCircle.png");
const GrayCircle = require("../assets/GrayCircle.png");
const BlackLogoTransparent = require("../assets/BlackLogoTransparent.png");
const TickerImage = (Ticker: string) =>
  `https://financialmodelingprep.com/image-stock/${Ticker}.png`;
function addDays(date: Date, days: number) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}
export const EarningsCalendarContent = () => {
  const [newEarningsEnable, setNewEarningsEnable] = useState(true);
  const printRef = useRef<HTMLDivElement>(null);
  const { user } = useContext(UserContext);

  const {
    earnings: newEarnings,
    loading: newLoading,
    error: newError,
  } = useNewEarningsData(user!);
  const dm = weeklyDate(new Date(new Date().toUTCString().slice(0, -4)));
  const dt = weeklyDate(new Date(new Date().toUTCString().slice(0, -4)));
  const dw = weeklyDate(new Date(new Date().toUTCString().slice(0, -4)));
  const dth = weeklyDate(new Date(new Date().toUTCString().slice(0, -4)));
  const df = weeklyDate(new Date(new Date().toUTCString().slice(0, -4)));

  const dmm = weeklyDate(new Date());
  const dtt = weeklyDate(new Date());
  const dww = weeklyDate(new Date());
  const dthh = weeklyDate(new Date());
  const dff = weeklyDate(new Date());

  const Monday = formatLongDate(dm, 0);
  const Tuesday = formatLongDate(dt, 1);
  const Wednesday = formatLongDate(dw, 2);
  const Thursday = formatLongDate(dth, 3);
  const Friday = formatLongDate(df, 4);

  const Mondaynum = formatDate(dmm, 0);
  const Tuesdaynum = formatDate(dtt, 1);
  const Wednesdaynum = formatDate(dww, 2);
  const Thursdaynum = formatDate(dthh, 3);
  const Fridaynum = formatDate(dff, 4);

  const handleDownloadImage = async () => {
    const element = printRef.current;
    const canvas = await html2canvas(element!, {
      height: 600,
      width: 1263,
      useCORS: true,
      backgroundColor: "#121111",
      imageTimeout: 15000,
      proxy: "Server",
    }).then(async (canvas) => {
      // Send image to server using post request
      const image = canvas.toDataURL("image/png", 1.0);
      fetch(image)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File(
            [blob],
            `earnings-report-week-of-${Mondaynum}.png`,
            { type: "image/png" }
          );
          const formData = new FormData();
          formData.append("file", file);
          axios
            .post("https://asebot.com/dev/api/guilds/upload", formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((response) => {
              // handle the response
              alert(response.data);
              console.log(response);
            })
            .catch((error) => {
              // handle errors
              alert(error);
              console.log(error);
            });
        });
    });
  };
  if (newLoading) {
    return (
      <Flex>
        <BarLoader color={"#BFA874"} loading={newLoading} />
      </Flex>
    );
  }
  if (newEarnings === undefined) {
    return (
      <Flex>
        <div>No Earnings Data Available</div>
      </Flex>
    );
  }
  const MondayBMO = newEarnings[Mondaynum].bmo;
  const MondayAMC = newEarnings[Mondaynum].amc;
  const TuesdayBMO = newEarnings[Tuesdaynum].bmo;
  const TuesdayAMC = newEarnings[Tuesdaynum].amc;
  const WednesdayBMO = newEarnings[Wednesdaynum].bmo;
  const WednesdayAMC = newEarnings[Wednesdaynum].amc;
  const ThursdayBMO = newEarnings[Thursdaynum].bmo;
  const ThursdayAMC = newEarnings[Thursdaynum].amc;
  const FridayBMO = newEarnings[Fridaynum].bmo;
  const FridayAMC = newEarnings[Fridaynum].amc;
  console.log(MondayBMO);
  const arr = Array.from({ length: 5 }, (_, i) => i);
  return (
    <>
      <div>
        {/* <Container id="Earnings" style={{ marginLeft: "0px", width: "1500px" }}> */}
        <GridContainer ref={printRef}>
          <Row style={{ height: "50px" }}>
            <Col
              sm={1}
              style={{
                textAlign: "center",
                backgroundColor: "#BFA874",
                border: "1px solid #BFA874",
                borderTopLeftRadius: "5px",
                color: "black",
                fontSize: "40px",
                height: "50px",
              }}
            >
              <img
                src={BlackLogoTransparent}
                alt="logo"
                width="60"
                height="60"
              />
            </Col>
            <Col
              sm={9}
              style={{
                backgroundColor: "#BFA874",
                border: "1px solid #BFA874",
                borderTopRightRadius: "5px",
                color: "black",
                fontSize: "40px",
                height: "50px",
              }}
            >
              <div style={{ textAlign: "center", marginRight: "120px" }}>
                Weekly Earnings Calendar
              </div>
            </Col>
          </Row>
          <Row style={{ height: "25px" }}>
            <Col
              sm={1}
              style={{
                textAlign: "center",
                backgroundColor: "#BFA874",
                border: "1px solid #BFA874",
                borderBottomLeftRadius: "5px",
                color: "black",
                fontSize: "16px",
                height: "25px",
              }}
            >
              <div />
            </Col>
            <Col
              sm={9}
              style={{
                backgroundColor: "#BFA874",
                border: "1px solid #BFA874",
                borderBottomRightRadius: "5px",
                color: "black",
                fontSize: "16px",
                height: "25px",
              }}
            >
              <div style={{ textAlign: "center", marginRight: "120px" }}>
                {Monday} to {Friday}
              </div>
            </Col>
          </Row>
          <Row style={{ height: "20px" }}>
            <Col
              sm={2}
              style={{
                textAlign: "center",
                backgroundColor: "#BFA874",
                border: "1px solid #000000",
                borderRadius: "5px",
                color: "black",
                fontSize: "15px",
              }}
            >
              {Monday}
            </Col>

            <Col
              sm={2}
              style={{
                textAlign: "center",
                backgroundColor: "#BFA874",
                border: "1px solid #000000",
                borderRadius: "5px",
                color: "black",
                fontSize: "15px",
              }}
            >
              {Tuesday}
            </Col>

            <Col
              sm={2}
              style={{
                textAlign: "center",
                backgroundColor: "#BFA874",
                border: "1px solid #000000",
                borderRadius: "5px",
                color: "black",
                fontSize: "15px",
              }}
            >
              {Wednesday}
            </Col>

            <Col
              sm={2}
              style={{
                textAlign: "center",
                backgroundColor: "#BFA874",
                border: "1px solid #000000",
                borderRadius: "5px",
                color: "black",
                fontSize: "15px",
              }}
            >
              {Thursday}
            </Col>

            <Col
              sm={2}
              style={{
                textAlign: "center",
                backgroundColor: "#BFA874",
                border: "1px solid #000000",
                borderRadius: "5px",
                color: "black",
                fontSize: "15px",
              }}
            >
              {Friday}
            </Col>
          </Row>
          <Row>
            <Col
              sm={1}
              style={{
                textAlign: "center",
                border: "1px solid #BFA874",
                borderRadius: "5px",
              }}
            >
              Before
            </Col>
            <Col
              sm={1}
              style={{
                textAlign: "center",
                border: "1px solid #BFA874",
                borderRadius: "5px",
              }}
            >
              After
            </Col>

            <Col
              sm={1}
              style={{
                textAlign: "center",
                border: "1px solid #BFA874",
                borderRadius: "5px",
              }}
            >
              Before
            </Col>

            <Col
              sm={1}
              style={{
                textAlign: "center",
                border: "1px solid #BFA874",
                borderRadius: "5px",
              }}
            >
              After
            </Col>

            <Col
              sm={1}
              style={{
                textAlign: "center",
                border: "1px solid #BFA874",
                borderRadius: "5px",
              }}
            >
              Before
            </Col>

            <Col
              sm={1}
              style={{
                textAlign: "center",
                border: "1px solid #BFA874",
                borderRadius: "5px",
              }}
            >
              After
            </Col>

            <Col
              sm={1}
              style={{
                textAlign: "center",
                border: "1px solid #BFA874",
                borderRadius: "5px",
              }}
            >
              Before
            </Col>

            <Col
              sm={1}
              style={{
                textAlign: "center",
                border: "1px solid #BFA874",
                borderRadius: "5px",
              }}
            >
              After
            </Col>

            <Col
              sm={1}
              style={{
                textAlign: "center",
                border: "1px solid #BFA874",
                borderRadius: "5px",
              }}
            >
              Before
            </Col>

            <Col
              sm={1}
              style={{
                textAlign: "center",
                border: "1px solid #BFA874",
                borderRadius: "5px",
              }}
            >
              After
            </Col>
          </Row>
          <Row>
            <Col sm={1} style={{ textAlign: "center" }}>
              <div style={{ padding: "10px" }} />
            </Col>
            <Col sm={1} style={{ textAlign: "center" }}>
              <div style={{ padding: "10px" }} />
            </Col>

            <Col sm={1} style={{ textAlign: "center" }}>
              <div style={{ padding: "10px" }} />
            </Col>

            <Col sm={1} style={{ textAlign: "center" }}>
              <div style={{ padding: "10px" }} />
            </Col>

            <Col sm={1} style={{ textAlign: "center" }}>
              <div style={{ padding: "10px" }} />
            </Col>

            <Col sm={1} style={{ textAlign: "center" }}>
              <div style={{ padding: "10px" }} />
            </Col>

            <Col sm={1} style={{ textAlign: "center" }}>
              <div style={{ padding: "10px" }} />
            </Col>

            <Col sm={1} style={{ textAlign: "center" }}>
              <div style={{ padding: "10px" }} />
            </Col>

            <Col sm={1} style={{ textAlign: "center" }}>
              <div style={{ padding: "10px" }} />
            </Col>

            <Col sm={1} style={{ textAlign: "center" }}>
              <div style={{ padding: "10px" }} />
            </Col>
          </Row>
          {arr.map((i) => {
            return (
              <>
                <Row key={i}>
                  <Col sm={1} style={{ textAlign: "center" }}>
                    {MondayBMO.length > i ? (
                      <ImageText>
                        {MondayBMO[i]["symbol"]}
                        <StockImage
                          src={TickerImage(MondayBMO[i].symbol)}
                          alt="img"
                          width="50"
                          height="50"
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = GrayCircle;
                          }}
                        />
                      </ImageText>
                    ) : (
                      <div />
                    )}
                  </Col>
                  <Col sm={1} style={{ textAlign: "center" }}>
                    {MondayAMC.length > i ? (
                      <ImageText>
                        {MondayAMC[i]["symbol"]}
                        <StockImage
                          src={TickerImage(MondayAMC[i].symbol)}
                          alt="img"
                          width="50"
                          height="50"
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = GrayCircle;
                          }}
                        />
                      </ImageText>
                    ) : (
                      <div />
                    )}
                  </Col>
                  <Col sm={1} style={{ textAlign: "center" }}>
                    {TuesdayBMO.length > i ? (
                      <ImageText>
                        {TuesdayBMO[i]["symbol"]}
                        <StockImage
                          src={TickerImage(TuesdayBMO[i].symbol)}
                          alt="img"
                          width="50"
                          height="50"
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = GrayCircle;
                          }}
                        />
                      </ImageText>
                    ) : (
                      <div />
                    )}
                  </Col>
                  <Col sm={1} style={{ textAlign: "center" }}>
                    {TuesdayAMC.length > i ? (
                      <ImageText>
                        {TuesdayAMC[i]["symbol"]}
                        <StockImage
                          src={TickerImage(TuesdayAMC[i].symbol)}
                          alt="img"
                          width="50"
                          height="50"
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = GrayCircle;
                          }}
                        />
                      </ImageText>
                    ) : (
                      <div />
                    )}
                  </Col>
                  <Col sm={1} style={{ textAlign: "center" }}>
                    {WednesdayBMO.length > i ? (
                      <ImageText>
                        {WednesdayBMO[i]["symbol"]}
                        <StockImage
                          src={TickerImage(WednesdayBMO[i].symbol)}
                          alt="img"
                          width="50"
                          height="50"
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = GrayCircle;
                          }}
                        />
                      </ImageText>
                    ) : (
                      <div />
                    )}
                  </Col>
                  <Col sm={1} style={{ textAlign: "center" }}>
                    {WednesdayAMC.length > i ? (
                      <ImageText>
                        {WednesdayAMC[i]["symbol"]}
                        <StockImage
                          src={TickerImage(WednesdayAMC[i].symbol)}
                          alt="img"
                          width="50"
                          height="50"
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = GrayCircle;
                          }}
                        />
                      </ImageText>
                    ) : (
                      <div />
                    )}
                  </Col>
                  <Col sm={1} style={{ textAlign: "center" }}>
                    {ThursdayBMO.length > i ? (
                      <ImageText>
                        {ThursdayBMO[i]["symbol"]}
                        <StockImage
                          src={TickerImage(ThursdayBMO[i].symbol)}
                          alt="img"
                          width="50"
                          height="50"
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = GrayCircle;
                          }}
                        />
                      </ImageText>
                    ) : (
                      <div />
                    )}
                  </Col>
                  <Col sm={1} style={{ textAlign: "center" }}>
                    {ThursdayAMC.length > i ? (
                      <ImageText>
                        {ThursdayAMC[i]["symbol"]}
                        <StockImage
                          src={TickerImage(ThursdayAMC[i].symbol)}
                          alt="img"
                          width="50"
                          height="50"
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = GrayCircle;
                          }}
                        />
                      </ImageText>
                    ) : (
                      <div />
                    )}
                  </Col>
                  <Col sm={1} style={{ textAlign: "center" }}>
                    {FridayBMO.length > i ? (
                      <ImageText>
                        {FridayBMO[i]["symbol"]}
                        <StockImage
                          src={TickerImage(FridayBMO[i].symbol)}
                          alt="img"
                          width="50"
                          height="50"
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = GrayCircle;
                          }}
                        />
                      </ImageText>
                    ) : (
                      <div />
                    )}
                  </Col>
                  <Col sm={1} style={{ textAlign: "center" }}>
                    {FridayAMC.length > i ? (
                      <ImageText>
                        {FridayAMC[i]["symbol"]}
                        <StockImage
                          src={TickerImage(FridayAMC[i].symbol)}
                          alt="img"
                          width="50"
                          height="50"
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = GrayCircle;
                          }}
                        />
                      </ImageText>
                    ) : (
                      <div />
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col sm={1} style={{ textAlign: "center" }}>
                    <div style={{ padding: "10px" }} />
                  </Col>
                  <Col sm={1} style={{ textAlign: "center" }}>
                    <div style={{ padding: "10px" }} />
                  </Col>

                  <Col sm={1} style={{ textAlign: "center" }}>
                    <div style={{ padding: "10px" }} />
                  </Col>

                  <Col sm={1} style={{ textAlign: "center" }}>
                    <div style={{ padding: "10px" }} />
                  </Col>

                  <Col sm={1} style={{ textAlign: "center" }}>
                    <div style={{ padding: "10px" }} />
                  </Col>

                  <Col sm={1} style={{ textAlign: "center" }}>
                    <div style={{ padding: "10px" }} />
                  </Col>

                  <Col sm={1} style={{ textAlign: "center" }}>
                    <div style={{ padding: "10px" }} />
                  </Col>

                  <Col sm={1} style={{ textAlign: "center" }}>
                    <div style={{ padding: "10px" }} />
                  </Col>

                  <Col sm={1} style={{ textAlign: "center" }}>
                    <div style={{ padding: "10px" }} />
                  </Col>

                  <Col sm={1} style={{ textAlign: "center" }}>
                    <div style={{ padding: "10px" }} />
                  </Col>
                </Row>
              </>
            );
          })}
        </GridContainer>
      </div>
      <Button
        variant="primary"
        style={{ margin: "20px" }}
        onClick={handleDownloadImage}
      >
        Download as Image
      </Button>
      {/* /Users/johnannunziato/ASE Algo Github/Discord-Dashboard-Backend/uploads/earnings/earnings-report-week-of-2023-06-26.png */}
    </>
  );
};
