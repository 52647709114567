import { AppBarStyle, ButtonInput, MainButton } from "../utils/styles";
import { useContext } from "react";
import { GuildContext } from "../utils/context/GuildContext";
import { getDefaultUserIcon, getIconURL, getUserIconURL } from "../utils/helpers";
import { useFetchUser } from "../utils/hooks/useFetchUser";
import { useProSidebar } from "react-pro-sidebar";
import { IoReorderThreeOutline } from "react-icons/io5";
import { useParams } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { RxDashboard } from "react-icons/rx";
import { FaDiscord } from "react-icons/fa";
import { UserContext } from "../utils/context/UserContext";

export const SubscribeBar = ({ isGuild }: { isGuild: boolean }) => {
  const { id } = useParams();
  const { guild, } = useContext(GuildContext);
  const { user } = useContext(UserContext);
  const redirect = () => {
    window.history.back()
  }
  const redirectToHome = () => {
    window.location.href = 'https://asebot.com/home';
  }

  return (user) ? (
    <AppBarStyle>
      <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
        <AiOutlineArrowLeft style={{ position: "fixed", display: "flex", justifyContent: "center" }} size={40} onClick={redirectToHome} />
        <h3 style={{marginLeft: "50px"}}>Back To Home</h3>
      </div>
      <div />
      <div>
        <MainButton style={{ width: "auto", padding: "4px 25px", height: "50px", marginTop: "20px", alignContent: "center", marginRight: "20px" }} onClick={redirect}>
          {user ? <RxDashboard size={20} color='#BFA874' /> : <FaDiscord size={20} color='#BFA874' />}
          {user ? <p style={{
            fontSize: '14px', marginLeft: "10px"
          }}>Enter Dashboard</p> :
            <p style={{
              fontSize: '14px', marginLeft: "10px"
            }}>Login with Discord</p>}
        </MainButton>
      </div>
    </AppBarStyle>
  ) : null;
};