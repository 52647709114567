import { Container, Flex, Page, Title } from "../utils/styles";
import { useContext } from "react";
import { GuildContext } from "../utils/context/GuildContext";
import { MoonLoader } from "react-spinners";
import { EconomicCalendarSelect, SwitchDemo, EconomicCalendarSelectDemo, EconomicCalendarTimeSelect, EconomicCalendarRoleSelect } from "../components/ChannelSelectEconomicCalendar";
import Select from "react-select";
import { useEconomicCalendar } from "../utils/hooks/useEconomicCalendar";

export const EconomicCalendarContent = () => {
  const { guild } = useContext(GuildContext);
  const guildId = (guild && guild.id) || '';
  const { config, channels, loading } = useEconomicCalendar(guildId);

  return (
    <Page>
      {channels && config && !loading ? (
        <Container style={{ width: 'auto', maxWidth: '800px', position: 'absolute', left: '25vw' }}>
          <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
            <SwitchDemo {...config} />
            <div style={{ marginLeft: "15px" }} />
            <Title>Enable Economic Calendar</Title>
          </div>
          <div style={{ padding: "15px" }} />
          <div>
            <section>
              <div style={{ marginBottom: "10px" }}>
                <label>Select Channel</label>
              </div>
              {/*<DropdownMenu
              onChange={(e) => {
                setSelectedChannel(e.target.value)
              }}
            >
              <option disabled>Please select a channel</option>
              {
                channels.map((channel) => <option selected={channel.id === config.welcomeChannelID} value={channel.id}>#{channel.name}</option>)
              }
            </DropdownMenu>*/}
              {/*<EconomicCalendarSelect {...config} />*/}
              <EconomicCalendarSelectDemo {...config} />
            </section>
            <div style={{ padding: "15px" }} />
            <section>
              <div style={{ marginBottom: "10px" }}>
                <label>Select Time</label>
              </div>
              <EconomicCalendarTimeSelect {...config} />
            </section>
            <div style={{ padding: "15px" }} />
            <section>
              <div style={{ marginBottom: "10px" }}>
                <label>Select Role</label>
              </div>
              <EconomicCalendarRoleSelect {...config} />
            </section>
          </div>
        </ Container>) : (<Container> <Flex justifyContent="center" alignItems="center"><MoonLoader size={30} color="white"></MoonLoader></Flex> </ Container>)}
    </Page>
  );
};
