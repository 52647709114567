import axios from "axios";
export const FINMODELAPI = "https://financialmodelingprep.com/api/v3";
export const DARQUBE = "https://api.darqube.com/data-api";

function addDay(date: Date, intToAdd: number) {
  date.setDate(date.getDate() + intToAdd);
  return date;
}

export function formatDate(date: Date, intToAdd: number) {
  const dateString = addDay(date, intToAdd).toISOString().split("T")[0];
  console.log(dateString, date);
  return dateString;
}

export function formatLongDate(date: Date, intToAdd: number) {
  const dateString = addDay(date, intToAdd).toDateString();
  return dateString;
}
function addDays(date: Date, days: number) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}
export function weeklyDate(date: Date) {
  var days =
    date.getDay() === 0
      ? -1
      : date.getDay() === 1
      ? 0
      : date.getDay() === 2
      ? 1
      : date.getDay() === 3
      ? 2
      : date.getDay() === 4
      ? 3
      : date.getDay() === 5
      ? 4
      : 5;
  date.setDate(date.getDate() - days);
  return date;
}

export const getEarningsCalendar = async () => {
  //const Monday = formatDate(new Date(), 1, "America/Detroit");
  //const Friday = formatDate(new Date(), 5, "America/Detroit");
  const dm = await weeklyDate(new Date());
  const df = await weeklyDate(new Date());
  const Monday = await formatDate(dm, 0);
  const Friday = await formatDate(df, 4);
  const EarningsCalendarAPI =
    await `${DARQUBE}/economic_calendar/earnings?token=${process.env.REACT_APP_DARUBE}&start_date=${Monday}&end_date=${Friday}&skip=0&country_iso=US&currency=USD&sort_order=asc`;
  return await axios.get(EarningsCalendarAPI, {
    withCredentials: true,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    },
  });
};
