import { Body, Page, RouteHeader, Spacer, TitleHeader } from "../Types/types"


export const Refund = () => {

  return (
    <Page style={{ paddingRight: "10vw", paddingLeft: "10vw" }}>
      <RouteHeader>Refund Policy</RouteHeader>
      <Spacer />
      <Body>Our subscription is recurring and automatically renews. You have the option to cancel at any time.</Body>
      <Body>We do not offer refunds, as our membership provides access to proprietary tools, assets, and knowledge. However, you can cancel your membership at any time. To cancel, please contact someone on our team through email, discord group message, support ticket, direct message, or any other communication method and inform them of your cancellation request.</Body>
      <Body>Please note that there are no refunds for any membership fees or installments. If you encounter any issues, you can reach out to an admin (Mamba#2369), contact us via email at Contact@asealgo.com, or open a support ticket.</Body>
      <Body>It is important to follow our rules, as any violations may result in an immediate ban without warning. This includes sharing invites or engaging in rude and negative behavior. In such cases, there will be no refund of premium subscription fees, and it is your responsibility to cancel the auto-renewal.We prioritize a respectful and positive environment within our chat/server. If you fail to maintain this standard, we reserve the right to ban you from the server without refunding your subscription cost (for premium members) and you will need to cancel the auto-renewal.</Body>
      <Body> Thank you for your understanding.</Body>
    </Page>
  )
}