import { useParams } from "react-router-dom";
import { Page } from "../utils/styles";
import { EconomicCalendarContent } from "./EconomicCalendarContent";

export const EconomicCalendar = () => {
  const { id } = useParams();
  const util = id;
  return (
    <Page>
      <EconomicCalendarContent />
    </Page>
  );
};
