import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Route } from 'react-router-dom';
import { ProSidebarProvider } from 'react-pro-sidebar';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { RSWGlobalStyle } from "react-simple-widgets/dist/style";
const stripePromise = loadStripe(
  'pk_test_51MhHFWBYaIa0dAi7UMuU87U26g0yhh72o51xKyaTe5VCR12SEYb2qp8MiolKsgahaSrC8ywFJW6B7s3FVuXZxPRV00FuqnJQaE'
);


const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <Elements stripe={stripePromise}>
    <Route>
      <ProSidebarProvider>
        <App />
      </ProSidebarProvider>
    </Route>
  </Elements>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
