import { FaDiscord, FaQuestionCircle } from 'react-icons/fa'
import { LoginPageStyle, MainButton } from '../utils/styles'
const ASEBotTransparent = require('../assets/ASEBotTransparent.png')

export const LoginPage = () => {
  const redirect = () => {
    window.location.href = 'http://127.0.0.1:3003/dev/api/auth/login';
  }

  return (
    <LoginPageStyle>
      <div>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}>
          <img src={ASEBotTransparent} alt="logo" height={125} />
          <h1 style={{
            fontSize: '75px'
          }}>ASE Bot</h1>
        </div>
      </div>
      <div>
        <MainButton onClick={redirect}>
          <FaDiscord size={45} color='#BFA874' />
          <p style={{
            fontSize: '18px'
          }}>Login with Discord</p>
        </MainButton>
        <MainButton>
          <FaQuestionCircle size={45} color='#BFA874' />
          <p style={{
            fontSize: '18px'
          }}>Support Server</p>
        </MainButton>
      </div>
      <div style={{ width: '85vw', maxWidth: '400px', display: 'flex', justifyContent: 'space-between' }}>
        <span>Privacy Policy</span>
        <span>Terms of Service</span>
        <span>Contact us</span>
      </div>
    </LoginPageStyle >
  );
}