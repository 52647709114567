import React from "react";
import { FooterBody, FooterElement, FooterLink, FooterMenu } from "../Types/Footer";


const Footer = () => {

    return (
        <FooterElement>
            <FooterMenu>
                <FooterLink to='/privacy'>
                    Privacy Policy
                </FooterLink>
                <FooterLink to='/terms'>
                    Terms of Service
                </FooterLink>
                <FooterLink to='/refunds'>
                    Refund Policy
                </FooterLink>
            </FooterMenu>
            <FooterBody>©2023 ASE Group LLC. All rights reserved </FooterBody>
        </FooterElement>
    );
};
export default Footer;
