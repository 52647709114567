//import { Body, Page, Video, VideoPage } from "../Types/types"
import { ChevronDownIcon } from "@radix-ui/react-icons";
import * as Radix from "@radix-ui/react-select";
import React, { useContext, useState } from "react";
import { Fade } from "react-awesome-reveal";
import { AiOutlineArrowDown } from "react-icons/ai";
import { BarLoader } from "react-spinners";
import StarSky from "react-star-sky";
import { GuildContext } from "../utils/context/GuildContext";
import { UserContext } from "../utils/context/UserContext";
import { useFetchGuilds } from "../utils/hooks/useFetchGuilds";
import {
  Content,
  Dropdown,
  HomePageContainer,
  Item,
  Section,
  SelTrigger,
  Viewport,
} from "../utils/styles";
import "../utils/styles/price.css";

const EconomicCalendarWeekly = require("../assets/EconomicCalendarWeekly.png");
const EarningsReport = require("../assets/EarningsReport.png");
// declare global {
//   namespace JSX {
//     interface IntrinsicElements {
//       "stripe-pricing-table": React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
//     }
//   }
// }
const error = null;

export const Home = () => {
  const { user } = useContext(UserContext);
  const [guildstring, setGuilds] = React.useState<string>();
  const { guild, updateGuild } = useContext(GuildContext);
  const { guilds, loading } = useFetchGuilds();
  const [toggled, setToggled] = useState("closed");
  const liveMontlyPrice = "price_1NN3BIBYaIa0dAi7O7RCfwyi";
  const handleChange = async (name: string) => {
    try {
      const findSetSelect =
        guilds && guilds.find((guild) => guild.name === name);
      setGuilds(findSetSelect!.id);
    } catch (err) {
      console.log(err);
    }
  };
  // const MonthlySubscribe = () => {
  //   if (guildstring !== undefined) {
  //     createCheckout(liveMontlyPrice, guildstring).then(res => {
  //       window.location.href = res.data
  //     })
  //   } else {
  //     alert("Please select a server")
  //   }
  // }

  const SUBSCRIBE = () => {
    window.location.href =
      user && user.premium
        ? "https://asebot.com/menu"
        : user
        ? "https://asebot.com/home/subscribe"
        : "https://asebot.com/dev/api/auth/login";
  };
  const Discord = () => {
    window.location.href = "http://asebot.com/dev/api/auth/login";
  };
  return (
    <>
      <StarSky
        debugFps={false} // Default = false
        frameRate={60} // Default = 60
        style={{
          position: "fixed",
          width: "100vw",
          height: "calc(100vh + 100px)",
          zIndex: "-9",
          top: "-100px",
        }} // applied to the div wrapper
        starColor={[191, 168, 116]} // Default = 'white', options: 'rainbow', 'white/red/green/etc'[w3color], [r, g, b]
        skyColor={[20, 20, 100]} // Default = 'black', options: 'white/red/green/etc'[w3color], [r, g, b]
        starCount={500}
        size={10}
      />
      <HomePageContainer id="container">
        <Section id="home">
          <div />
          <div style={{ display: "grid", textAlign: "center" }}>
            <h1 style={{ fontSize: "70px" }}>ASE Bot</h1>
            <h1>Discord's Best Financial Bot</h1>
          </div>
          <div />
          <div />
          <div
            style={{
              display: "grid",
              textAlign: "center",
              alignItems: "center",
              justifyItems: "center",
            }}
          >
            <h1>Features</h1>
            <AiOutlineArrowDown size={40} />
          </div>
        </Section>
        <Section id="features">
          <div />
          <div />
          <div
            style={{
              display: "grid",
              textAlign: "center",
              justifyItems: "center",
              maxWidth: "100vw",
            }}
          >
            <h1 style={{ textDecoration: "underline" }}>Current Features</h1>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fit, minmax(18rem, 1fr))",
                textAlign: "center",
                maxWidth: "100vw",
              }}
            >
              <div
                style={{
                  display: "grid",
                  textAlign: "center",
                  justifyItems: "center",
                }}
              >
                <h2>Economic Event Summary (Daily and Weekly)</h2>
                <img
                  src={EconomicCalendarWeekly}
                  alt="Earnings Report"
                  height={254}
                  width={205}
                />
              </div>
              <div
                style={{
                  display: "grid",
                  textAlign: "center",
                  justifyItems: "center",
                }}
              >
                <h2>Earnings Calendar Weekly Summary</h2>
                <img
                  src={EarningsReport}
                  alt="Earnings Report"
                  height={180}
                  width={384}
                />
              </div>
            </div>
          </div>
          {/* <div style={{ display: "grid", textAlign: "center", alignItems: "center", justifyItems: 'center' }}>
            <h1 style={{ textDecoration: "underline" }}>Upcoming Features</h1>
            <h3>• Crypto, Forex, Equities, and General News Feeds</h3>
            <h3>• Stock Grade Upgrade/Downgrade Feed</h3>
          </div> */}
          <div />
          <div
            style={{
              display: "grid",
              textAlign: "center",
              alignItems: "center",
              justifyItems: "center",
            }}
          >
            <h1>Pricing</h1>
            <AiOutlineArrowDown size={40} />
          </div>
          <div />
        </Section>
        <div />
        <div className="lastContainer" style={{ color: "white" }} id="pricing">
          <Fade duration={3000} triggerOnce>
            <div
              style={{
                display: "grid",
                textAlign: "center",
                placeItems: "center",
                width: "90vw",
              }}
            >
              <section className="pricing-plans">
                <div className="pricing-card basic">
                  <div className="heading">
                    <h4>MONTHLY</h4>
                  </div>
                  <p className="price">
                    $35
                    <sub>/month</sub>
                  </p>
                  <ul className="features">
                    <li>
                      <i className="fa-solid fa-check"></i>
                      <strong>Earnings Report</strong>
                    </li>
                    <li>
                      <i className="fa-solid fa-check"></i>
                      <strong>Daily and Weekly Economic News</strong>
                    </li>
                    <li>
                      <i className="fa-solid fa-check"></i>
                      <strong>Forex News Alerts</strong>
                    </li>
                    <li>
                      <i className="fa-solid fa-check"></i>
                      <strong>Crypto News Alerts</strong>
                    </li>
                    <li>
                      <i className="fa-solid fa-check"></i>
                      <strong>Stock News Alerts</strong>
                    </li>
                    <li>
                      <i className="fa-solid fa-check"></i>
                      <strong>General News Alerts</strong>
                    </li>
                    <li>
                      <i className="fa-solid fa-check"></i>
                      <strong>Stock Ratings Alerts</strong>
                    </li>
                    <li>
                      <i className="fa-solid fa-check"></i>
                      <strong>Upcoming Features...</strong>
                    </li>
                    {/* <li>
                    <i className="fa-solid fa-check"></i>
                    <strong>Exclusive Signal Bots</strong>
                  </li> */}
                  </ul>
                  {guilds && user && !loading ? (
                    <div>
                      <Radix.Root
                        dir="ltr"
                        // open={true}
                        onOpenChange={(e: any) =>
                          setToggled(e === true ? "open" : "closed")
                        }
                        onValueChange={handleChange}
                      >
                        <Radix.Trigger
                          asChild
                          data-state={toggled}
                          style={{ maxHeight: "30px" }}
                        >
                          <SelTrigger error={!!error}>
                            <span>
                              <Radix.Value />
                            </span>
                            <Radix.Icon asChild>
                              <ChevronDownIcon />
                            </Radix.Icon>
                          </SelTrigger>
                        </Radix.Trigger>
                        <Radix.Portal>
                          <Content
                            asChild
                            position="popper"
                            style={{ backgroundColor: "black" }}
                          >
                            <Dropdown>
                              <Viewport>
                                {guilds &&
                                  guilds.map((guild) => {
                                    return (
                                      <Item key={guild.id} value={guild.name}>
                                        <Radix.ItemText>
                                          {" "}
                                          {guild.name}{" "}
                                        </Radix.ItemText>
                                      </Item>
                                    );
                                  })}
                              </Viewport>
                            </Dropdown>
                          </Content>
                        </Radix.Portal>
                      </Radix.Root>
                      {/* <button className="cta-btn" onClick={MonthlySubscribe}>SUBSCRIBE</button> */}
                    </div>
                  ) : loading && user ? (
                    <div>
                      <BarLoader color={"#BFA874"} />
                    </div>
                  ) : (
                    <div>
                      <button className="cta-btn" onClick={Discord}>
                        Login With Discord
                      </button>
                    </div>
                  )}
                </div>
              </section>
            </div>
          </Fade>
        </div>
      </HomePageContainer>
    </>
  );
};
