import { ElementsConsumer } from "@stripe/react-stripe-js";
import { useContext } from "react";
import { BarLoader, HashLoader } from "react-spinners";
import { PaymentForm } from "../components/PaymentForm";
import { Spinner } from "../components/Spinner";
import { UserContext } from "../utils/context/UserContext";
import { useFetchUser } from "../utils/hooks/useFetchUser";
import { HomePageContainer, Section } from "../utils/styles";

export const SubscribePage = () => {
    const { user } = useContext(UserContext);
    return (user) ? (
        <HomePageContainer>
            <Section>
                <div>
                    <PaymentForm />
                </div>
            </Section>
        </HomePageContainer>
    ) : (
        <HomePageContainer>
            <Section>
                <h1>Please log in to discord!</h1>
            </Section>
        </HomePageContainer>
    );
}