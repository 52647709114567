import { useContext, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import { Spinner } from './components/Spinner';
import { CategoryPage } from './pages/CategoryPage';
import { LoginPage } from './pages/LoginPage';
import { MenuPage } from './pages/MenuPage';
import { GuildContext } from './utils/context/GuildContext';
import { useFetchUser } from './utils/hooks/useFetchUser';
import {
  HashLoader
} from 'react-spinners';
import { PartialGuild } from './utils/types';
import { SideBar } from './components/SideBar';
import { SidebarData } from './utils/SideBarData';
import { EconomicCalendar } from './pages/EconomicCalendar';
import { AppBar } from './components/AppBar';
import { CustomEmbed } from './pages/CustomEmbed';
import { CustomEmbedContent } from './pages/CustomEmbedContent';
import { EarningsReport } from './pages/EarningsReport';
import { HomePage } from './utils/styles';
import { Home } from './pages/Home';
import { Navbar } from './components/NavBar';
import { PaymentForm } from './components/PaymentForm';
import { SubscribePage } from './pages/SubscribePage';
import { SubscribeBar } from './components/SubscribeBar';
import { UserContext } from './utils/context/UserContext';
import { AccountSideBar } from './components/AccountSideBar';
import { AccountPage } from './pages/AccountPage';
import { AccountAppBar } from './components/AccountAppBar';
import { TVWebhook } from './pages/TVWebhook';
import Footer from './components/Footer';
import { Terms } from './pages/terms';
import { Refund } from './pages/Refund';
import { Privacy } from './pages/privacy';
import { News } from './pages/News';

const GuildProvider = ({ children }: any) => {
  const [guild, setGuild] = useState<PartialGuild>();
  const updateGuild = (guild: PartialGuild) => setGuild(guild);
  return <GuildContext.Provider value={{ guild, updateGuild }}>
    {children}
  </GuildContext.Provider>;
}

function App() {
  const [guild, setGuild] = useState<PartialGuild>();
  const { user, loading, error } = useFetchUser();
  // const { user } = useContext(UserContext);
  const updateGuild = (guild: PartialGuild) => setGuild(guild);
  if (loading) return <Spinner children={
    <HashLoader
      color="#BFA874" />
  } />;
  //
  return (
    <UserContext.Provider value={{ user }}>
      <GuildContext.Provider value={{ guild, updateGuild }}>
        {user && !error && (user.id == 1 || user.id == 2) ? (
          <>
            <Routes>
              <Route path="/admin/dashboard/:id/earnings" element={<EarningsReport />} />
            </Routes>
            <Routes>
              <Route path="/home" element={<Navbar home={true} />} />
              <Route path="/terms" element={<Navbar home={false} />} />
              <Route path="/privacy" element={<Navbar home={false} />} />
              <Route path="/refunds" element={<Navbar home={false} />} />
            </Routes>
            {/* <Routes>
              <Route path="/account/*" element={<AccountSideBar />} />
            </Routes>
            <Routes>
              <Route path="/account/*" element={<AccountAppBar />} />
            </Routes> */}
            <Routes>
              <Route path="/home/subscribe" element={<SubscribeBar isGuild={false} />} />
            </Routes>
            <Routes>
              <Route path="/dashboard/:id/*" element={<SideBar />} />
            </Routes>
            <Routes>
              <Route path="/dashboard/:id/*" element={<AppBar isGuild={true} />} />
            </Routes>
            <Routes>
              <Route path="/dashboard/:id/economic" element={<EconomicCalendar />} />
              <Route path="/dashboard/:id/news" element={<News />} />
              <Route path="/dashboard/:id/earnings" element={<EarningsReport />} />
              <Route path="/dashboard/:id/embed" element={<CustomEmbedContent />} />
              <Route path="/dashboard/:id/webhook" element={<TVWebhook />} />
            </Routes>
            <Routes>
              <Route path="/dashboard/:id" element={<CategoryPage />} />

            </Routes>
            <Routes>
              <Route path="/menu" element={<AppBar isGuild={false} />} />
            </Routes>
            <Routes>
              <Route path="/" element={<Navigate to="/menu" />} />
              {/* <Route path="/account" element={<AccountPage />} /> */}
              <Route path="/menu" element={<MenuPage />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/refunds" element={<Refund />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/home/subscribe" element={<SubscribePage />} />
              <Route path="/home" element={<Home />} />
            </Routes>
            <Routes>
              {/* <Route path="/account" element={<Footer />} /> */}
              <Route path="/menu" element={<Footer />} />
              <Route path="/home/subscribe" element={<Footer />} />
              <Route path="/home" element={<Footer />} />
              <Route path="/terms" element={<Footer />} />
              <Route path="/refunds" element={<Footer />} />
              <Route path="/privacy" element={<Footer />} />
            </Routes>
          </>
        ) : user && !error ? (
          <>
            <Routes>
              <Route path="/home/" element={<Navbar home={true} />} />
              <Route path="/terms" element={<Navbar home={false} />} />
              <Route path="/privacy" element={<Navbar home={false} />} />
              <Route path="/refunds" element={<Navbar home={false} />} />
            </Routes>
            <Routes>
              <Route path="/home/subscribe" element={<SubscribeBar isGuild={false} />} />
            </Routes>
            <Routes>
              <Route path="/dashboard/:id/*" element={<SideBar />} />
            </Routes>
            <Routes>
              <Route path="/dashboard/:id/*" element={<AppBar isGuild={true} />} />
            </Routes>
            <Routes>
              <Route path="/dashboard/:id/economic" element={<EconomicCalendar />} />
              <Route path="/dashboard/:id/news" element={<News />} />
              <Route path="/dashboard/:id/earnings" element={<EarningsReport />} />
              <Route path="/dashboard/:id/embed" element={<CustomEmbedContent />} />
            </Routes>
            <Routes>
              <Route path="/dashboard/:id" element={<CategoryPage />} />
            </Routes>
            <Routes>
              <Route path="/menu" element={<AppBar isGuild={false} />} />
            </Routes>
            <Routes>
              <Route path="/" element={<Navigate to="/menu" />} />
              <Route path="/menu" element={<MenuPage />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/refunds" element={<Refund />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/home/subscribe" element={<Navigate to="/menu" />} />
              <Route path="/home" element={<Home />} />
            </Routes>
            <Routes>
              <Route path="/menu" element={<Footer />} />
              <Route path="/home/subscribe" element={<Footer />} />
              <Route path="/home" element={<Footer />} />
              <Route path="/terms" element={<Footer />} />
              <Route path="/refunds" element={<Footer />} />
              <Route path="/privacy" element={<Footer />} />
            </Routes>
          </>
        )
          : (
            <><Routes>
              <Route path="/home/*" element={<Navbar home={true} />} />
              <Route path="/terms" element={<Navbar home={false} />} />
              <Route path="/privacy" element={<Navbar home={false} />} />
              <Route path="/refunds" element={<Navbar home={false} />} />
            </Routes>
              <Routes>
                <Route path="/home/subscribe" element={<SubscribePage />} />
                <Route path="/home" element={<Home />} />
                <Route path="/terms" element={<Terms />} />
                <Route path="/refunds" element={<Refund />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="*" element={<Navigate to="/" />} />
                <Route path="/" element={<Navigate to="/home" />} />
              </Routes>
              <Routes>
                <Route path="/menu" element={<Footer />} />
                <Route path="/home/subscribe" element={<Footer />} />
                <Route path="/home" element={<Footer />} />
                <Route path="/terms" element={<Footer />} />
                <Route path="/refunds" element={<Footer />} />
                <Route path="/privacy" element={<Footer />} />
              </Routes>
            </>
          )}
      </GuildContext.Provider >
    </UserContext.Provider>
  );
}

export default App;
