import { Container, Flex, Page, Title } from "../utils/styles";
import { useContext } from "react";
import { GuildContext } from "../utils/context/GuildContext";
import { MoonLoader } from "react-spinners";
import { EconomicCalendarSelect, SwitchDemo, EconomicCalendarSelectDemo, EconomicCalendarTimeSelect, EconomicCalendarRoleSelect } from "../components/ChannelSelectEconomicCalendar";
import Select from "react-select";
import { useEconomicCalendar } from "../utils/hooks/useEconomicCalendar";
import { NewsSelectDemo, NewsSwitchDemo } from "../components/ChannelSelectNews";

export const NewsContent = () => {
  const { guild } = useContext(GuildContext);
  const guildId = (guild && guild.id) || '';
  const { config, channels, loading, selectedChannel, setSelectedChannel } = useEconomicCalendar(guildId);

  const Stock = "stock";
  const Crypto = "crypto";
  const Forex = "forex";
  const General = "general";
  const Upgrade = "upgrade";
  return (
    <Page>
      {channels && config && !loading ? (
        <Container style={{ width: 'auto', maxWidth: '800px', position: 'absolute', left: '25vw' }}>
          <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
            <section>
              <NewsSelectDemo config={config} type={Stock} channels={channels} loading={loading} />
            </section>
            <NewsSwitchDemo type={Stock} config={config} loading={loading} />
            <div style={{ marginLeft: "15px" }} />
            <Title>Enable Stock News</Title>
          </div>
          <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
            <section>
              <NewsSelectDemo config={config} type={Crypto} channels={channels} loading={loading} />
            </section>
            <NewsSwitchDemo type={Crypto} config={config} loading={loading} />
            <div style={{ marginLeft: "15px" }} />
            <Title>Enable Crypto News</Title>
          </div>
          <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
            <section>
              <NewsSelectDemo config={config} type={Forex} channels={channels} loading={loading} />
            </section>
            <NewsSwitchDemo type={Forex} config={config} loading={loading} />
            <div style={{ marginLeft: "15px" }} />
            <Title>Enable Forex News</Title>
          </div>
          <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
            <section>
              <NewsSelectDemo config={config} type={General} channels={channels} loading={loading} />
            </section>
            <NewsSwitchDemo type={General} config={config} loading={loading} />
            <div style={{ marginLeft: "15px" }} />
            <Title>Enable General News</Title>
          </div>
          <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
            <section>
              <NewsSelectDemo config={config} type={Upgrade} channels={channels} loading={loading} />
            </section>
            <NewsSwitchDemo type={Upgrade} config={config} loading={loading} />
            <div style={{ marginLeft: "15px" }} />
            <Title>Enable Upgrade Downgrade News</Title>
          </div>
          <div style={{ padding: "15px" }} />
        </ Container>) : (<Container> <Flex justifyContent="center" alignItems="center"><MoonLoader size={30} color="white"></MoonLoader></Flex> </ Container>)}
    </Page>
  );
};
