import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import React, { useContext, useState } from 'react';
import { updatePremiumStatus } from '../utils/api';
import { UserContext } from '../utils/context/UserContext';
import { useFetchUser } from '../utils/hooks/useFetchUser';
import { Section, MainButton } from '../utils/styles';
import { FaDiscord } from 'react-icons/fa';
import { RxDashboard } from 'react-icons/rx'
import "../utils/styles/stripe.css";

const redirect = () => {
    window.location.href = 'https://asebot.com/menu';

}

export const PaymentForm = () => {
    const stripe = useStripe();
    const [processing, setProcessing] = useState(false);
    const [success, setSuccess] = useState(false);
    const elements = useElements();
    const { user } = useContext(UserContext);

    // const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    //     e.preventDefault();
    //     if (!stripe || !elements) {
    //         throw new Error('Stripe has not loaded');
    //     }
    //     setProcessing(true);
    //     const card = elements.getElement(CardElement);
    //     if (!card) throw new Error('Card Elements Not Found');
    //     if (!user) throw new Error('User Not Found');
    //     try {
    //         const { data }: { data: any } = await createPaymentIntent(user.id, user.customerID);
    //         const { error, paymentIntent } = await stripe.confirmCardPayment(
    //             data,
    //             {
    //                 payment_method: {
    //                     card,
    //                 },
    //             }
    //         );
    //         if (error) throw error;
    //         await updatePremiumStatus(true);
    //         setProcessing(false);
    //         setSuccess(true);
    //     } catch (err) {
    //         console.log(err);
    //     }
    // };

    return (!success && user && user.premium != true) ? (
        <div>
            <h1>ASE Bot Subscription</h1>
            <h2>$20.00/month</h2>
            <form style={{ display: "flex", flexDirection: "column", minWidth: "300px", justifyContent: "center", alignItems: "center" }}>
                <CardElement
                    options={{
                        hidePostalCode: true,
                        style: {
                            base: {
                                color: 'white',
                            },
                            complete: {
                                color: 'white'
                            }

                        },
                    }}
                />
                <button style={{ backgroundColor: "#BFA874", padding: "10px", color: "black", borderRadius: "5px" }}>{processing ? "Processing..." : "Pay"}</button>
            </form>
        </div>
    ) : (
        <Section>
            <h1>Payment Sucess</h1>
            <MainButton style={{ width: "auto", padding: "4px 25px", height: "50px", marginTop: "20px", alignContent: "center", marginRight: "20px" }} onClick={redirect}>
                <RxDashboard size={20} color='#BFA874' />
                <p style={{
                    fontSize: '14px', marginLeft: "10px"
                }}>Enter Dashboard</p>
            </MainButton>
        </Section>

    );
};
