import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom"
import { BarLoader } from "react-spinners";
import { AppBar } from "../components/AppBar";
import { GuildMenuItem } from "../components/GuildMenuItem";
import { GuildContext } from "../utils/context/GuildContext";
import { useFetchGuilds } from "../utils/hooks/useFetchGuilds";
import { useUnaddedGuilds } from "../utils/hooks/useUnaddedGuilds";
import { Button, Container, Flex } from "../utils/styles";
import { PartialGuild } from "../utils/types";

export const MenuPage = () => {
  const navigate = useNavigate();
  const { updateGuild } = useContext(GuildContext);
  const { guilds, loading } = useFetchGuilds();
  const { unaddedGuilds } = useUnaddedGuilds();
  const [id, setId] = useState("");

  const handleClick = async (guild: PartialGuild) => {
    try {
      updateGuild(guild);
      setId(guild.id);
      const id = guild.id;
      id && navigate(`/dashboard/${id}`);
    } catch (err) {
      console.log(err);
    }

  };

  const addBotLink = "https://discord.com/api/oauth2/authorize?client_id=1060731288325206036&permissions=4026657904&redirect_uri=https%3A%2F%2Fasebot.com%2Fdev%2Fapi%2Fauth%2Fredirect&scope=bot";

  return (
    <div style={{
      padding: '50px 0', display: 'flex',
      alignContent: 'center', height: "calc(100vh - 320px)"
    }}>
      <Container>
        <h2 style={{ textAlign: 'center' }}>Select a Server</h2>
        <div style={{ display: "grid", placeItems: "center" }}>
          {loading ? (<Flex justifyContent="center">
            <BarLoader color="white" />
          </Flex>) : guilds && guilds.length > 0 ? (
            <div style={{ maxHeight: "50vh", overflowY: 'scroll', width: '860px' }}>
              {guilds && guilds.map((guild) => (
                <div>
                  <GuildMenuItem guild={guild} />
                </div>
              ))}
            </div>
          ) : (
            <h2>You do not have any servers, please add using button below.</h2>
          )}
        </div>
        <div></div>
        <Flex justifyContent="center" alignItems="center" style={{ marginTop: "20px" }} >
          <Button variant="primary" onClick={() => window.open(addBotLink)}>Add Bot to Server</Button>
        </Flex>
      </Container>
    </div>
  );
};