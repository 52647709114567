import styled from 'styled-components';
import { Link } from 'react-router-dom'
import * as Radix from '@radix-ui/react-select';
import { styled as styled2 } from "@stitches/react";
import { FaBars } from 'react-icons/fa';
import { Menu } from '@mui/material';

export const MainButton = styled.div`
  align-items: center;
  display: flex;
  width: 350px;
  justify-content: space-between;
  background-color: #000000;
  padding: 4px 50px;
  box-sizing: border-box;
  border-radius: 50px;
  border: 1px solid #BFA874;
  margin: 10px 0;
  box-shadow: 0px 1px 3px 0px #BFA874;
`;

export const DefaultButton = styled.div`
  align-items: center;
  display: flex;
  width: 350px;
  justify-content: space-between;
  background-color: #000000;
  padding: 4px 50px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid #BFA874;
  margin: 10px 0;
  box-shadow: 0px 1px 3px 0px #BFA874;
`;

export const TextButton = styled(DefaultButton)`
  width: 100%;
  padding: 18px 28px;
`;

export const LoginPageStyle = styled.div`
  height: 100%;
  padding: 50px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const GuildMenuItemStyle = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 20px;
  background-color: #000000;
  border-radius: 5px;
  border: 1px solid #BFA874;
  align-items: center;
  margin: 0px auto;
  width: 85vw;
  max-width: 800px;
  vertical-align: center;
`;

export const Container = styled.div`
  width: 100vw;
  height: calc(60vh - 300px);
  margin: 0 0;
`;

export const AppBarStyle = styled.header`
  display: flex;
  align-items: center;
  padding: 15px 35px;
  box-sizing: border- box;
  border-bottom: 1px solid #BFA87421;
  justify-content: space-between;
  maxWidth: 100%;
  background-color: #090909;
`;

export const Title = styled.p`
  font-size: 22px;
`;

type FlexProps = Partial<{
  alignItems: string;
  justifyContent: string;
  flexDirection: string;
}>
export const Flex = styled.div<FlexProps>`
  display: flex;
  align-items: ${({ alignItems }) => alignItems};
  justify-content:${({ justifyContent }) => justifyContent};
  flex-direction:${({ flexDirection }) => flexDirection};
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 2fr;
  column-gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
`;

export const GridButtons = styled.div`
  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;
`;

export const InputField = styled.input`
  padding: 14px 16px;
  font-size: 16px;
  box-sizing: border-box;
  font-family: 'Tinos', serif;
  color: #ffffffd3;
  background-color: #000000;
  border-radius: 5px;
  border: 1px solid #BFA874;
  outline: none;
  width: 100%;
  :focus {
    outline: 1px solid #fffff5a;
  }
`;
export const TextArea = styled.textarea`
  padding: 14px 16px;
  font-size: 16px;
  box-sizing: border-box;
  font-family: 'Tinos', serif;
  color: #ffffffd3;
  background-color: #000000;
  border-radius: 5px;
  border: 1px solid #BFA874;
  outline: none;
  resize: none;
  width: 100%;
  :focus {
    outline: 1px solid #fffff5a;
  }
`;
type ButtonProps = {
  variant: 'primary' | 'secondary';
}
export const Button = styled.button<ButtonProps>`
  padding: 12px 24px;
  border-radius: 5px;
  outline: none;
  border: ${({ variant }) =>
    variant === 'primary' ? 'none' : variant === 'secondary' ? '1px solid #BFA874' : 'none'};
  cursor: pointer;
  font-size: 16px;
  color: ${({ variant }) =>
    variant === 'primary' ? '#000000' : variant === 'secondary' ? '#BFA874' : 'none'};
  font-family: 'Tinos', serif;
  background-color: ${({ variant }) =>
    variant === 'primary' ? '#BFA874' : variant === 'secondary' ? '#000000' : '#fffff'};
`;

export const Button2 = styled.button<ButtonProps>`
  padding: 12px 24px;
  border-radius: 5px;
  outline: none;
  border: ${({ variant }) =>
    variant === 'primary' ? 'none' : variant === 'secondary' ? '1px solid #BFA874' : 'none'};
  cursor: pointer;
  margin-left: 10px;
  font-size: 16px;
  height: 20px;
  color: ${({ variant }) =>
    variant === 'primary' ? '#000000' : variant === 'secondary' ? '#BFA874' : 'none'};
  font-family: 'Tinos', serif;
  background-color: ${({ variant }) =>
    variant === 'primary' ? '#BFA874' : variant === 'secondary' ? '#000000' : '#fffff'};
`;


export const ButtonInput = styled.input<ButtonProps>`
  padding: 12px 24px;
  border-radius: 5px;
  outline: none;
  border: ${({ variant }) =>
    variant === 'primary' ? 'none' : variant === 'secondary' ? '1px solid #BFA874' : 'none'};
  cursor: pointer;
  font-size: 16px;
  color: ${({ variant }) =>
    variant === 'primary' ? '#000000' : variant === 'secondary' ? '#BFA874' : 'none'};
  font-family: 'Tinos', serif;
  background-color: ${({ variant }) =>
    variant === 'primary' ? '#BFA874' : variant === 'secondary' ? '#000000' : '#fffff'};
`;

export const Page = styled.div`
  padding: 35px;
  maxWidth: 100%;
  overflow: hidden;
`;

export const ERPage = styled.div`
  padding: 35px;
  overflow: hidden;
`;

export const gridSpacer = styled.div`
  padding: 0px 5px 0px 5px;
`;

export const DropdownMenu = styled.select`
  width: 100%;
  padding: 10px;
  font-family: 'Tinos', serif;
  background-color: #090909f7;
  border-color: #BFA874;
  border-radius: 5px;
  outline: none;
  color: #ffffff
`;

export const GuildPage = styled.div`
  height: 90vh;
`;

export const Overlay = styled.div`
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const Navbar = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
    height: 3.5rem;
    background-color: #000080;
`

export const MenuIconOpen = styled(Link)`
    display: flex;
    justify-content: start;
    font-size: 1.5rem;
    margin-left: 2rem;
    color: #ffffff;
`

export const MenuIconClose = styled(Link)`
    display: flex;
    justify-content: end;
    font-size: 1.5rem;
    margin-top: 0.75rem;
    margin-right: 1rem;
    color: #ffffff;
`

export const SidebarMenu = styled.div<{ close: boolean }>`
    width: 250px;
    height: 100vh;
    background-color: #000080;
    position: fixed;
    top: 0;
    left: ${({ close }) => close ? '0' : '-100%'};
    transition: .6s;
`

export const MenuItems = styled.li`
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: start;
    width: 100%;
    height: 90px;
    padding: 1rem 0 1.25rem;
`

export const MenuItemLinks = styled(Link)`
    display: flex;
    align-items: center;
    padding: 0 2rem;
    font-size: 20px;
    text-decoration: none;
    color: #ffffff;

    &:hover {
        background-color: #ffffff;
        color: #000080;
        width: 100%;
        height: 45px;
        text-align: center;
        border-radius: 5px;
        margin: 0 2rem;
    }
`

export const Wrapper = styled2("div", {
  position: "relative",
  display: "flex",
  alignItems: "center",
  width: "100%"
});

export const SelTrigger = styled2("button", {
  position: "relative",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  boxSizing: "border-box",
  background: "#000000",
  padding: 16,
  fontSize: 16,
  fontFamily: "Quicksand",
  border: "1px solid #BFA874",
  borderRadius: 5,
  outline: "none",
  color: "#fff",
  variants: {
    error: {
      true: {
        borderColor: "#df6c75"
      }
    }
  }
});

export const Dropdown = styled2("div", {
  position: "relative",
  boxSizing: "border-box",
  color: "#fff",
  padding: "16px 8px",
  fontFamily: "Quicksand",
  fontSize: 16,
  marginBottom: 8,
  background: "#000000",
  border: "1px solid #BFA874",
  borderRadius: 4,
  boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
  pointerEvents: "all"
});

export const Viewport = styled2(Radix.Viewport, {
  display: "flex",
  flexDirection: "column",
  rowGap: 8,
});

export const Item = styled2(Radix.Item, {
  padding: "8px",
  outline: "none",
  transition: "background ease 150ms",
  borderRadius: 4,
  "&:focus": {
    background: "#35363a"
  }
});

export const Content = styled2(Radix.Content, {
  width: "var(--radix-select-trigger-width)",
  maxHeight: "var(--radix-select-content-available-height)",
  radixSideTop: "animate-slide-up",
  radixSideBottom: "animate-slide-down"
});

export const ImageText = styled.div`
align-items: center;
justify-content: center;
display: flex;
flex-direction: column
`;

export const StockImage = styled.img`
border-radius: 50%; 
border: 1px solid #BFA874;
background-color: rgb(67,69,73);
`;

export const Logo = styled.img`
height: 60px;
width: 60px;
display: flex;
padding-top: 10px;
justify-content: flex-start;
align-items: center;
`;

export const Nav = styled.nav`
overflow: hidden;
background: #000000;
width: 100%;
border-bottom: solid #BFA874;
height: 85px;
display: flex;
justify-content: space-between;
z-index: 12;
/* Third Nav */
/* justify-content: flex-start; */

`;

export const NavLink = styled(Link)`
color: #BFA874;
display: flex;
align-items: center;
text-decoration: none;
padding: 0 1rem;
font-size: 24px; 
height: 100%;
cursor: pointer;
&.active {
	color: #BFA874;
}
`;
export const NavBarButton = styled.button`
color: #BFA874;
display: flex;
align-items: center;
text-decoration: none;
padding: 0 1rem;
font-size: 24px; 
height: 100%;
cursor: pointer;
&.active {
	color: #BFA874;
}`;
export const NavLinkBtn = styled(Link)`
color: #BFA874;
display: flex;
align-items: center;
text-decoration: none;
padding: 0 1rem;
font-size: 24px;
height: 100%;
cursor: pointer;
`;

export const Bars = styled(FaBars)`
display: none;
color: #BFA874;
@media screen and (max-width: 768px) {
	display: block;
	position: absolute;
	top: 0;
	right: 0;
	transform: translate(-100%, 75%);
	font-size: 1.8rem;
	cursor: pointer;
}
`;

export const StyledMenu = styled(Menu)`
backgroundColor: black;
border: solid #BFA874;
color: white;
`

export const NavMenu = styled.div`
display: flex;
align-content: center;
justify-content: center;
margin-right: -24px;
/* Second Nav */
/* margin-right: 24px; */
/* Third Nav */
/* width: 100vw;
white-space: nowrap; */
@media screen and (max-width: 768px) {
	display: none;
}
`;

export const NavBtn = styled.nav`
display: flex;
align-items: center;
margin-right: 24px;
/* Third Nav */
/* justify-content: flex-end;
width: 100vw; */
@media screen and (max-width: 768px) {
	display: none;
}
`;

export const NavBtnLink = styled.button`
border-radius: 4px;
background: #BFA874;
padding: 10px 22px;
color: #000000;
outline: none;
border: none;
cursor: pointer;
transition: all 0.2s ease-in-out;
text-decoration: none;
/* Second Nav */
&:hover {
	transition: all 0.2s ease-in-out;
	background: #000;
	color: #BFA874;
	border: solid #BFA874;
}
`;


export const HomePage = styled.div`
color: white;
textAlign: left;
margin-left: 100px; 
margin-right: 100px; 
padding-bottom: 120px;
min-height: calc(100vh - 365px )
`;

export const Spacer = styled.div`
padding-top: 10px;
padding-bottom; 10px;
`;
export const BodySpacer = styled.div`
padding-top: 20px;
padding-bottom; 20px;
`;

export const RouteHeader = styled.h1`
fontSize: 30px;
display: flex;
justify-content: center;
text-decoration: underline;
`;
export const TitleHeader = styled.h1`
font-size: 40px;
`;

export const Body = styled.body`
font-size: 18px;
`;

export const VideoPage = styled.div`
height: 100vh;
width: 100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
object-fit: contain;
`;

export const Video = styled.video`
object-fit: cover;
width: 100%;
height: 100%;
position: fixed;
z-index: -1;
`;

export const Section = styled.section`
display: grid;
place-items: center;
min-height: 100vh;
`

export const HomePageContainer = styled.div`
overflow: hidden;
display: grid;
justify-content: center; 
text-align: center;
align-items: center;
grid-column: 1;
`

export const PricingTableContainer = styled.div`
display: grid;
justify-items: center; 
align-items: center;
grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
width: auto;
max-width: 100vw;
min-height: 350px;
row-gap: 20px;
`;

export const PricingTableElement = styled.div`
border: 1px solid #BFA874;
display: grid;
text-align: center;
background: linear-gradient(to top, black 0%, black 80%, #BFA874 80%, #BFA874 100%);
color: black;
align-items: center;
justify-items: center;
width: 250px;
height: 350px;
border-radius: 5px;
grid-template-rows: 1fr 1fr 2fr 1fr;
position: relative;
`

export const PricingTableTitle = styled.h1`
margin-top: 0;
margin-bottom: 0;
color: black;
`
export const PricingTablePrice = styled.h2`
color: white;
margin-top: 0;
margin-bottom: 0;
padding: 0px;
`;

export const PricingTableDescriptionItem = styled.h3`
color: #BFA874;
margin-top: 0;
margin-bottom: 0;
padding: 0px;
`;

export const PricingTableDescriptionContainer = styled.div`
display: grid;
text-align: center; 
align-items: center; 
justify-items: center;
margin-bottom: 50px;
margin-top: 50px; 
`

export const PricingTableButton = styled.button`
background-color: #BFA874;
height: 40px;
width: 100px;
color: black;
border-radius: 5px;
font-weight: bold;
`;