import React, { useContext, useState } from 'react';
import {
  Sidebar,
  Menu,
  MenuItem,
  useProSidebar,
  menuClasses,
  MenuItemStyles,
} from 'react-pro-sidebar';
import { generatePath, Link, useNavigate, useParams } from 'react-router-dom';
import { useFetchUser } from '../utils/hooks/useFetchUser';
import { FaRegMoneyBillAlt } from 'react-icons/fa';
import { RxDashboard } from "react-icons/rx";
import { IoLogoWebComponent, IoNewspaper } from 'react-icons/io5'
import { SidebarFooter } from './SidebarFooter';
import { SidebarHeader } from './SidebarHeader';
import { Typography } from './Typography';
import { Badge } from './Badge';
import { Calendar } from '../icons/Calendar';
import { GuildContext } from '../utils/context/GuildContext';
import { useFetchGuilds } from '../utils/hooks/useFetchGuilds';
import styled from '@emotion/styled';
import * as Radix from '@radix-ui/react-select';
import { Button, Flex, GridButtons, Dropdown, SelTrigger, Viewport, Item, Content } from '../utils/styles';
import { ChevronDownIcon } from '@radix-ui/react-icons';
import '../utils/styles/styles.css';
import { getIconURL } from '../utils/helpers';
import { BiMessageRoundedEdit, BiArrowToLeft, BiArrowToRight } from "react-icons/bi";
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { BarLoader } from "react-spinners";
import { UserContext } from '../utils/context/UserContext';
const GrayCircle = require("../assets/GrayCircle.png");
interface SidebarHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
}
const error = null;
const StyledSidebarHeader = styled.div`
  height: 64px;
  min-height: 64px;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;

  > div {
    width: 100%;
    overflow: hidden;
  }
`;

export const TextOverImage = styled.div`
  position: relative;
  text-align: center;
`;
const themes = {
  sidebar: {
    backgroundColor: '#090909',
    color: '#ffffff',
  },
  menu: {
    menuContent: '#BFA874',
    icon: '#BFA874',
    hover: {
      backgroundColor: '#BFA874',
      color: '#090909',
    },
    disabled: {
      color: '#808080',
    },
  },
};

// hex to rgba converter
const hexToRgba = (hex: string, alpha: number) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const SideBar: React.FC = () => {
  const { id } = useParams();
  const { toggleSidebar, collapseSidebar, broken, collapsed } = useProSidebar();
  const { user } = useContext(UserContext);
  const { guild, updateGuild } = useContext(GuildContext);
  const { guilds, loading } = useFetchGuilds();
  const [toggled, setToggled] = useState("closed");
  const navigate = useNavigate();
  const handleChange = async (name: string) => {
    try {
      const findSetSelect = guilds && guilds.find((guild) => guild.name === name);
      updateGuild(findSetSelect!);
      const id = findSetSelect && findSetSelect.id;
      id && navigate(`/dashboard/${id}/`);

    } catch (err) {
      console.log(err);
    }
  };
  const menuItemStyles: MenuItemStyles = {
    root: {
      fontSize: '18px',
      fontWeight: 400,
    },
    icon: {
      color: themes.menu.icon,
      [`&.${menuClasses.disabled}`]: {
        color: themes.menu.disabled.color,
      }, '&:hover': {
        color: themes.menu.hover.color,
      },
    },
    SubMenuExpandIcon: {
      color: '#BFA874',
    },
    subMenuContent: ({ level }) => ({
      backgroundColor:
        level === 0
          ? hexToRgba(themes.menu.menuContent, !collapsed ? 0.4 : 1)
          : 'transparent',
    }),
    button: {
      [`&.${menuClasses.disabled}`]: {
        color: themes.menu.disabled.color,
      },
      '&:hover': {
        backgroundColor: hexToRgba(themes.menu.hover.backgroundColor, 1),
        color: themes.menu.hover.color,
      },
    },
    label: ({ open }) => ({
      fontWeight: open ? 600 : undefined,
    }),
  };
  const redirectToHome = () => {
    window.location.href = 'https://asebot.com/menu';
  }

  return (
    <div style={{ display: 'flex', height: '100vh', overflow: 'hidden', position: 'absolute' }}>
      <Sidebar
        breakPoint="xl"
        backgroundColor={hexToRgba(themes.sidebar.backgroundColor, 1)}
        rootStyles={{
          color: themes.sidebar.color,
          borderRight: '1px solid #BFA87421',
        }}
        width="300px"
      >
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%', }}>
          {!collapsed ? <AiOutlineArrowLeft style={{ position: "fixed", marginLeft: "20px", marginTop: "20px" }} size={20} onClick={redirectToHome} /> : null}
          {!collapsed && !broken ? <BiArrowToLeft style={{ marginLeft: "auto", marginRight: "20px", marginTop: "20px" }} size={20} onClick={() => collapseSidebar()} /> : !broken ? <BiArrowToRight style={{ marginLeft: "auto", marginRight: "20px", marginTop: "20px" }} size={20} onClick={() => collapseSidebar()} /> : null}
          <StyledSidebarHeader style={{ paddingTop: "20px", marginBottom: '12px', marginTop: '16px', maxWidth: '300px' }}>
            <img
              src={id && guild && guild.icon !== null ? getIconURL(guild!) : GrayCircle}
              alt={'img'}
              width={!collapsed ? 100 : 50}
              style={{ borderRadius: '50%', border: '3px solid #BFA874' }}
            />
            <div style={{ marginTop: "20px" }} />
            {!collapsed ?
              <Radix.Root
                dir="ltr"
                // open={true}
                defaultValue={guild && guild.name}
                onOpenChange={(e) => setToggled(e === true ? "open" : "closed")}
                onValueChange={handleChange}
              >
                <Radix.Trigger asChild data-state={toggled} style={{ maxHeight: "30px" }}>
                  <SelTrigger error={!!error}>
                    <span>

                      <Radix.Value />
                    </span>
                    <Radix.Icon asChild>
                      <ChevronDownIcon />
                    </Radix.Icon>
                  </SelTrigger>
                </Radix.Trigger>
                <Content asChild position="popper" sideOffset={5} sticky={"always"} style={{ backgroundColor: "black", zIndex: 999 }}>
                  <Dropdown>
                    <Viewport>
                      {!loading ? guilds && guilds.map((guild) => {
                        return (
                          <Item key={guild.id} value={guild.name} data-highlighted style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                            <img
                              src={guild.icon != null ? getIconURL(guild) : GrayCircle}
                              alt={'img'}
                              width={25}
                              style={{ borderRadius: '50%', border: '1px solid #BFA874', marginRight: "10px" }}
                            />
                            <Radix.ItemText> {guild.name} </Radix.ItemText>
                          </Item>
                        );
                      }) : <BarLoader />}
                    </Viewport>
                  </Dropdown>
                </Content>
              </Radix.Root> : null}
            <div style={{ marginTop: "20px" }} />
            {/*<Typography variant="subtitle1" fontWeight={700} color="#BFA874" style={{ paddingTop: "10px" }}>
        {guild.name}
  </Typography>*/}
          </StyledSidebarHeader>
          <div style={{ flex: 1, marginBottom: '32px' }}>
            <div style={{ padding: '0 24px', marginBottom: '8px', marginTop: '125px' }}>
              <Typography
                variant="body2"
                fontWeight={600}
                style={{ opacity: collapsed ? 0 : 0.7, letterSpacing: '0.5px' }}
              >
                General
              </Typography>
            </div>

            <Menu menuItemStyles={menuItemStyles}>
              <MenuItem icon={<RxDashboard />} component={<Link to={`/dashboard/${id}`} />} >
                Dashboard
              </MenuItem>
              <MenuItem suffix={<Badge variant="success" >New</Badge>} icon={<Calendar />} component={<Link to={`/dashboard/${id}/economic`} />}>Economic Calendar</MenuItem>
              <MenuItem suffix={<Badge variant="success" >New</Badge>} icon={<IoNewspaper />} component={<Link to={`/dashboard/${id}/news`} />}>News Alerts</MenuItem>
              <MenuItem disabled suffix={<Badge variant="success" >New</Badge>} icon={<FaRegMoneyBillAlt />} component={<Link to={`/dashboard/${id}/earnings`} />}>
                Earnings Report
              </MenuItem>
            </Menu>
            {user && (user.id == 1 || user.id == 2) ?
              (<div style={{ flex: 1, marginBottom: '32px' }}><div style={{ padding: '0 24px', marginBottom: '8px', marginTop: '25px' }}>
                <Typography
                  variant="body2"
                  fontWeight={600}
                  style={{ opacity: collapsed ? 0 : 0.7, letterSpacing: '0.5px' }}
                >
                  Admin Utilities
                </Typography>
              </div>
                <Menu menuItemStyles={menuItemStyles}>
                  <MenuItem icon={<FaRegMoneyBillAlt />} component={<Link to={`/admin/dashboard/${id}/earnings`} />}>
                    Earnings Report Content
                  </MenuItem>
                  <MenuItem icon={<BiMessageRoundedEdit />} component={<Link to={`/dashboard/${id}/embed`} />} >
                    Custom Embeds
                  </MenuItem>
                  <MenuItem icon={<IoLogoWebComponent />} component={<Link to={`/dashboard/${id}/webhook`} />} >
                    TradingView Webhook
                  </MenuItem>
                </Menu></div>
              ) : (null)
            }
          </div>
          <SidebarFooter collapsed={collapsed} />
        </div>
      </Sidebar >
      {/*<main> 
        <div style={{ padding: '16px 24px', color: '#44596e' }}>
          <div style={{ marginBottom: '16px' }}>
            {broken && (
              <button className="sb-button" onClick={() => toggleSidebar()}>
                Toggle
              </button>
            )}
          </div>
          <div style={{ padding: '0 8px' }}>
            <div style={{ marginBottom: 16 }}>
              <Switch
                id="collapse"
                checked={collapsed}
                onChange={() => collapseSidebar()}
                label="Collapse"
              />
            </div>
          </div>
        </div>
            </main> */}
    </div >
  );
};
