import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const FooterElement = styled.footer`
background: #000000;
border-top: solid #BFA874;
bottom:0;
left:0;
width:100%;
height: 110px;
overflow: hidden;
`;
export const FooterBody = styled.body`
color: rgb(200,200,200);
background: #000000;
display: flex;
justify-content: center;
align-items: center;
`;

export const FooterLink = styled(Link)`
color: #808080;
display: flex;
justify-content: center;
align-items: center;
text-decoration: none;
padding: 0 1rem;
height: 100%;
cursor: pointer;
&.active {
	color: #BFA874;
}
`;

export const FooterMenu = styled.div`
display: flex;
align-items: center;
justify-content: center;
margin-top: 24px;
margin-right: -24px;
`;