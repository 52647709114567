import { useContext } from "react";
import { useParams } from "react-router-dom";
import { useGuildConfig } from "../utils/hooks/useFetchGuildConfig";
import { Page } from "../utils/styles";
import { CustomEmbedContent } from "./CustomEmbedContent";
import { TVWebhookContent } from "./TVWebhookContent";

export const TVWebhook = () => {
  const { id } = useParams();
  const util = id;
  return (
    <Page>
      <TVWebhookContent />
    </Page>
  );
};