import { useContext } from "react";
import { useParams, useLocation } from "react-router-dom";
import { updateGuildPrefix } from "../utils/api";
import { GuildContext } from "../utils/context/GuildContext";
import { useGuildConfig } from "../utils/hooks/useFetchGuildConfig";
import { Page } from "../utils/styles";
import { GuildPrefixPage } from "./GuildPrefixPage";
import { WelcomeMessagePage } from "./WelcomeMessagePage";

export const CategoryPage = () => {
  const { id } = useParams();
  const { guild } = useContext(GuildContext);
  const guildId = (guild && guild.id) || '';
  const { prefix } = useGuildConfig(guildId);
  return (
    <Page>
      <GuildPrefixPage />
    </Page>)
};
