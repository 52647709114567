import { useParams } from "react-router-dom";
import { Page } from "../utils/styles";
import { EconomicCalendarContent } from "./EconomicCalendarContent";
import { NewsContent } from "./NewsContent";

export const News = () => {
  const { id } = useParams();
  const util = id;
  return (
    <Page>
      <NewsContent />
    </Page>
  );
};
