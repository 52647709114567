import { useEffect, useState } from "react";
import { getGuildChannels, getGuildConfig, getGuildRoles } from "../api";
import { GuildConfigType, PartialGuildChannel, PartialRoles } from "../types";

export function useEconomicCalendar(guildId: string) {
  const [config, setConfig] = useState<GuildConfigType>();
  const [channels, setChannels] = useState<PartialGuildChannel[]>();
  const [loading, setLoading] = useState(false);
  const [selectedChannel, setSelectedChannel] = useState<string>();

  useEffect(() => {
    setLoading(true);
    getGuildConfig(guildId)
      .then(({ data }) => {
        setConfig(data)
        setSelectedChannel(data.economic_calendar_channel_id)!;
        return getGuildChannels(guildId);
      })
      .then(({ data }) => {
        return setChannels(data)
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, []);

  return {
    config, channels, loading, selectedChannel, setSelectedChannel
  };
}

export function useEconomicCalendarEnable(guildId: string) {
  const [enableConfig, setConfig] = useState<GuildConfigType>();
  const [enableLoading, setLoading] = useState(false);
  const [selectedEnable, setSelectedEnable] = useState<string>();

  useEffect(() => {
    setLoading(true);
    getGuildConfig(guildId)
      .then(({ data }) => {
        setConfig(data)
        setSelectedEnable(data.economic_calendar_enable)!;
        return setSelectedEnable(data.economic_calendar_enable);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, []);
  return {
    enableConfig, enableLoading, selectedEnable, setSelectedEnable
  };
}

export function useRoleMention(guildId: string) {
  const [config, setConfig] = useState<GuildConfigType>();
  const [roles, setRoles] = useState<PartialRoles[]>();
  const [roleLoading, setRoleLoading] = useState(false);
  const [selectedRole, setSelectedRole] = useState<string>();

  useEffect(() => {
    setRoleLoading(true);
    getGuildConfig(guildId)
      .then(({ data }) => {
        setConfig(data)
        setSelectedRole(data.role_mention_id)!;
        return getGuildRoles(guildId);
      })
      .then(({ data }) => setRoles(data))
      .catch((err) => console.log(err))
      .finally(() => setRoleLoading(false));
  }, []);

  return {
    roles, roleLoading, selectedRole, setSelectedRole
  };
}

export function useRoleMentionID(guildId: string) {
  const [config, setConfig] = useState<GuildConfigType>();
  const [roles, setRoles] = useState<PartialGuildChannel[]>();
  const [roleLoading, setRoleLoading] = useState(false);
  const [selectedRole, setSelectedRole] = useState<string>();

  useEffect(() => {
    setRoleLoading(true);
    getGuildConfig(guildId)
      .then(({ data }) => {
        setConfig(data)
        setSelectedRole(data.economic_calendar_channel_id)!;
        return getGuildChannels(guildId);
      })
      .then(({ data }) => setRoles(data))
      .catch((err) => console.log(err))
      .finally(() => setRoleLoading(false));
  }, []);

  return {
    roles, roleLoading, selectedRole, setSelectedRole
  };
}