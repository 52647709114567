import { AppBarStyle, Button2, ButtonInput } from "../utils/styles";
import { useContext, useState } from "react";
import { GuildContext } from "../utils/context/GuildContext";
import { getDefaultUserIcon, getIconURL, getUserIconURL } from "../utils/helpers";
import { useFetchUser } from "../utils/hooks/useFetchUser";
import { useProSidebar } from "react-pro-sidebar";
import { IoReorderThreeOutline, IoChevronDown } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { UserContext } from "../utils/context/UserContext";

export const AppBar = ({ isGuild }: { isGuild: boolean }) => {
  const { id } = useParams();
  const { guild, } = useContext(GuildContext);
  const { user } = useContext(UserContext);
  const redirect = () => {
    window.history.back()
  }
  const redirectToHome = () => {
    window.location.href = 'https://asebot.com/home';
  }
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    navigate(`/account/`)
  };
  const handleCloseLogout = () => {
    setAnchorEl(null);
    window.location.href = 'https://asebot.com/dev/api/auth/logout'
  };
  const { toggleSidebar, collapseSidebar, broken, collapsed } = useProSidebar();

  return (user) ? (
    <AppBarStyle>
      {broken && isGuild && (
        <IoReorderThreeOutline type="button" onClick={() => toggleSidebar()} size="50px" />
      )}
      {!isGuild ? <AiOutlineArrowLeft style={{ position: "fixed", display: "flex", justifyContent: "center" }} size={40} onClick={redirectToHome} /> : null}
      <div />
      <div style={{ display: "flex", justifyContent: "center", alignItems: 'center' }}>
        <img
          src={user.avatar !== null ? getUserIconURL(user) : getDefaultUserIcon(user)}
          alt={'img'}
          width={50}
          style={{ borderRadius: '50%', border: '3px solid #BFA874' }}
        />
        <Button2 variant="primary" onClick={handleCloseLogout}>Logout</Button2>
        {/* <Menu
          id="positioned-menu"
          aria-labelledby="demo-positioned-button"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          style={{marginTop: "75px", marginRight: "40px"}}
          PaperProps={{
            style: {
              backgroundColor: "black",
              border: "solid #BFA874",
              color: "white"
            }
          }}
        >
          <MenuItem onClick={handleClose}>My account</MenuItem>
          <MenuItem onClick={handleCloseLogout}>Logout</MenuItem>
        </Menu> */}
      </div>
    </AppBarStyle >
  ) : null;
};