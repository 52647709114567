import { useContext, useState } from 'react';
import * as Radix from '@radix-ui/react-select';
import { ChevronDownIcon, CheckIcon, WidthIcon } from '@radix-ui/react-icons';
import { useWelcomePage } from '../utils/hooks/useWelcomePage';
import { GuildContext } from '../utils/context/GuildContext';
import { BarLoader } from 'react-spinners';
import { Button, Flex, GridButtons, Dropdown, SelTrigger, Viewport, Item, Content } from '../utils/styles';
import { updateEconomicCalendarChannelId, updateWelcomeChannelId, updateEconomicCalendarChannelEnable, updateEconomicCalendarTime, updateEconomicCalendarTimezone, updateRoleMention, updateRoleMentionEnable } from '../utils/api';
import { useEconomicCalendar, useEconomicCalendarEnable, useRoleMention } from '../utils/hooks/useEconomicCalendar';
import React, { Component } from "react";
import Switch from "react-switch";
import '../utils/styles/styles.css';
import { GuildConfigType, PartialGuild } from '../utils/types';
import classnames from 'classnames';
import Select from 'react-select';
import TimePicker from 'react-time-picker/dist/entry.nostyle';
import TimezoneSelect, { allTimezones, ITimezone } from 'react-timezone-select'
import { OnlyStringNumeric } from '@stitches/react/types/css';
const error = null;

export const SwitchDemo = (config: GuildConfigType) => {
  const { guild } = useContext(GuildContext);
  const guildId = (guild && guild.id) || '';
  const { enableConfig, enableLoading, setSelectedEnable, selectedEnable } = useEconomicCalendarEnable(guildId)
  const [checked, setChecked] = useState(config.economic_calendar_enable === "true" ? true : false);

  const handleChange = async (checked: boolean) => {
    try {
      setChecked(checked);
      setSelectedEnable(checked === false ? "false" : "true");
      const update = checked.toString();
      await updateEconomicCalendarChannelEnable(guildId, update);
    } catch (err) {
      console.log(err);
    }
  };
  return (!enableLoading && enableConfig) ? (
    <label>
      <Switch
        onChange={handleChange}
        checked={checked}
        onColor="#BFA874"
        onHandleColor="#ffffff"
        handleDiameter={30}
        uncheckedIcon={false}
        checkedIcon={false}
        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
        height={20}
        width={48}
        disabled={!config.premium}
        className="react-switch"
        id="material-switch" />
    </label>
  ) : (<Flex justifyContent="center" alignItems="center"><BarLoader color="white" /></Flex>)
}

export const EconomicCalendarSelect = (config: GuildConfigType) => {
  const [toggled, setToggled] = useState("closed");
  const { guild } = useContext(GuildContext);
  const guildId = (guild && guild.id) || '';
  const { channels, loading, selectedChannel, setSelectedChannel } = useEconomicCalendar(guildId);
  const find = channels?.find((channel) => channel.id === config.economic_calendar_channel_id);
  const updateEconomicCalendar = async () => {
    try {
      const findSetSelect = channels?.find((channel) => channel.name === selectedChannel);
      await updateEconomicCalendarChannelId(guildId, findSetSelect?.id || '');
    } catch (err) {
      console.log(err);
    }
  };
  //channels.find((channel) => channel.id === config.welcomeChannelID).name
  return (channels && config && !loading) ? (
    <Radix.Root
      dir="ltr"
      // open={true}
      defaultValue={channels && config && !loading && find && (find.name !== "undefined") ? find.name : undefined}
      onOpenChange={(e) => setToggled(e === true ? "open" : "closed")}
      onValueChange={(e) => {
        setSelectedChannel(e)
      }}
    >
      <Radix.Trigger asChild data-state={toggled} disabled={!config.premium}>
        <SelTrigger error={!!error}>
          <span>
            <Radix.Value placeholder="Please select a channel" />
          </span>
          <Radix.Icon asChild>
            <ChevronDownIcon />
          </Radix.Icon>
        </SelTrigger>
      </Radix.Trigger>
      <Content asChild position="popper"
        sideOffset={5}>
        <Dropdown>
          <Viewport>
            {channels.map((channel) => (
              <Item key={channel.id} value={channel.name}>
                <Radix.ItemText> {channel.name} </Radix.ItemText>
                <Radix.ItemText> {channel.id} </Radix.ItemText>
              </Item>
            )
            )}
          </Viewport>
        </Dropdown>
      </Content>
      <GridButtons style={{ marginTop: "10px" }}>
        <Button variant="secondary" type="button" style={{ marginRight: '8px' }}>Reset</Button>
        <Button variant="primary" onClick={updateEconomicCalendar}>Save</Button>
      </GridButtons>
    </Radix.Root>
  ) : (<Flex justifyContent="center" alignItems="center"><BarLoader color="white" /></Flex>)
}

export const EconomicCalendarSelectDemo = (config: GuildConfigType) => {
  const [toggled, setToggled] = useState("closed");
  const { guild } = useContext(GuildContext);
  const guildId = (guild && guild.id) || '';
  const { channels, loading, selectedChannel, setSelectedChannel } = useEconomicCalendar(guildId);
  const find = channels?.find((channel) => channel.id === config.economic_calendar_channel_id);

  const updateEconomicCalendar = async (id: string) => {
    try {
      await updateEconomicCalendarChannelId(guildId, id || '');
    } catch (err) {
      console.log(err);
    }
  };
  return (channels && config && !loading) ? (
    <Select
      name="channelSelect"

      classNamePrefix={"channelSelect"}
      options={channels}
      defaultValue={find}
      isDisabled={config.premium == false}
      onChange={(e) => {
        setSelectedChannel(e?.id)
        updateEconomicCalendar(e!.id)
      }}
      getOptionLabel={(channels) => `#${channels.name}`}
      getOptionValue={(channels) => channels.id}
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          backgroundColor: 'black',
          borderRadius: '5px',
          borderColor: '#BFA874',
          color: 'white',
          width: "50vw",
          maxWidth: "800px"
        }),
        menu: (base) => ({
          ...base,
          // override border radius to match the box
          borderColor: '#BFA874',
          borderRadius: 0,
          // kill the gap
          marginTop: 0,
          backgroundColor: "black",
          pointerEvents: "all",
          boxShadow: "#BFA874 0px 1px 4px"
        }),
      }}
      theme={(theme) => ({
        ...theme,
        borderRadius: 0,
        colors: {
          ...theme.colors,
          text: 'black',
          font: 'black',
          primary25: '#393939',
          primary75: '#BFA874',
          primary50: 'black',
          primary: '#BFA874',
          neutral80: 'white',
          color: 'white',
        }
      })}
    />
  ) : (<Flex justifyContent="center" alignItems="center"><BarLoader color="white" /></Flex>)
}

export const EconomicCalendarTimeSelect = (config: GuildConfigType) => {
  const { guild } = useContext(GuildContext);
  const guildId = (guild && guild.id) || '';
  const [value, onChange] = useState<any>(config.economic_calendar_time);

  const [selectedTimezone, setSelectedTimezone] = useState<ITimezone>(config.economic_calendar_timezone)
  //Intl.DateTimeFormat().resolvedOptions().timeZone
  const [isTimezoneChanged, setTimezoneChange] = useState<boolean>(false);
  const [isTimeChanged, setTimeChange] = useState<boolean>(false);
  const updateEconomicTime = async () => {
    try {
      if (isTimeChanged) { await updateEconomicCalendarTime(guildId, value); }
      if (isTimezoneChanged) { await updateEconomicCalendarTimezone(guildId, JSON.stringify(selectedTimezone).toString()); }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <Flex style={{ maxHeight: "35px" }}>
      <TimePicker
        value={config.economic_calendar_time}
        onChange={(date: any) => {
          onChange(date);
          setTimeChange(true);
        }
        }
        disableClock={true}
        disabled={!config.premium}
        clearIcon={null}
      />
      <div style={{ padding: "10px" }} />
      <TimezoneSelect
        value={selectedTimezone == null ? Intl.DateTimeFormat().resolvedOptions().timeZone : selectedTimezone}
        onChange={(data) => {
          setSelectedTimezone(data);
          setTimezoneChange(true);
        }
        }
        labelStyle="abbrev"
        isDisabled={!config.premium}
        timezones={{
          ...allTimezones,
        }}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            backgroundColor: 'black',
            borderRadius: '5px',
            borderColor: '#BFA874',
            color: 'white',
            maxWidth: "270px",
            width: "25vw"
          }),
          menu: (base) => ({
            ...base,
            // override border radius to match the box
            borderColor: '#BFA874',
            borderRadius: 0,
            // kill the gap
            marginTop: 0,
            backgroundColor: "black",
            pointerEvents: "all",
            boxShadow: "#BFA874 0px 1px 4px"
          }),
        }}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            text: 'black',
            font: 'black',
            primary25: '#393939',
            primary75: '#BFA874',
            primary50: 'black',
            primary: '#BFA874',
            neutral80: 'white',
            color: 'white',
          }
        })}
      />
      <div style={{ padding: "10px" }} />
      <Button style={{ verticalAlign: "middle", padding: "0 0", width: "50px", textAlign: "center" }} variant="primary" disabled={!config.premium} onClick={updateEconomicTime}>Save</Button>
    </Flex>
  );
}

export const EconomicCalendarRoleSelect = (config: GuildConfigType) => {
  const [toggled, setToggled] = useState("closed");
  const { guild } = useContext(GuildContext);
  const guildId = (guild && guild.id) || '';
  const { roles, roleLoading, selectedRole, setSelectedRole } = useRoleMention(guildId);
  const find = roles && roles.find((role) => role.id === config.role_mention_id);

  const updateRole = async (id: string | null) => {
    try {
      await updateRoleMention(guildId, id);
      await updateRoleMentionEnable(guildId, id == null ? "false" : "true").then((data) => console.log(data)).catch((err) => console.log(err))
    } catch (err) {
      console.log(err);
    }
  };
  return (roles && config && !roleLoading) ? (
    <Select
      name="channelSelect"
      classNamePrefix={"channelSelect"}
      options={roles}
      defaultValue={find}
      placeholder={"Choose a role"}
      isClearable
      isDisabled={!config.premium}
      onChange={(e) => {
        setSelectedRole(e && e.id ? e.id : undefined)
        updateRole(e && e.id ? e.id : null)
      }}
      getOptionLabel={(channels) => (channels.name.startsWith("@") ? `${channels.name}` : `@${channels.name}`)}
      getOptionValue={(channels) => channels.id}
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          backgroundColor: 'black',
          borderRadius: '5px',
          borderColor: '#BFA874',
          color: 'white',
          width: "50vw",
          maxWidth: "800px"
        }),
        menu: (base) => ({
          ...base,
          // override border radius to match the box
          borderColor: '#BFA874',
          borderRadius: 0,
          // kill the gap
          marginTop: 0,
          backgroundColor: "black",
          pointerEvents: "all",
          boxShadow: "#BFA874 0px 1px 4px"
        }),
      }}
      theme={(theme) => ({
        ...theme,
        borderRadius: 0,
        colors: {
          ...theme.colors,
          text: 'black',
          font: 'black',
          primary25: '#393939',
          primary75: '#BFA874',
          primary50: 'black',
          primary: '#BFA874',
          neutral80: 'white',
          color: 'white',
        }
      })}
    />
  ) : (<Flex justifyContent="center" alignItems="center"><BarLoader color="white" /></Flex>)
}