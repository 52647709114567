import { useEffect, useState } from "react";
import { getUnaddedGuilds } from "../api";
import { PartialGuild, User } from '../types';

export function useUnaddedGuilds() {
  const [unaddedGuilds, setUnaddedGuilds] = useState<PartialGuild[]>();
  const [unaddedLoading, setUnaddedLoading] = useState(false);
  const [unaddedError, setUnaddedError] = useState();

  useEffect(() => {
    setUnaddedLoading(true);
    getUnaddedGuilds().then(({ data }) => {
      setUnaddedGuilds(data)
    }).catch((err) => {
      console.log(err)
      setUnaddedError(err);
    }).finally(() => {
      setUnaddedLoading(false);
    });

  }, []);
  return { unaddedGuilds, unaddedLoading, unaddedError };
}