import { Button, Container, Flex, Page, TextArea } from "../utils/styles";
import createTheme from '@mui/material/styles/createTheme';
import green from "@mui/material/colors/green";
import { Grid, ThemeProvider } from "@mui/material";
import { useContext, useState } from "react";
import { HexColorPicker, HexColorInput } from "react-colorful";
import { useEconomicCalendar } from "../utils/hooks/useEconomicCalendar";
import { GuildContext } from "../utils/context/GuildContext";
import Select from 'react-select';
import { BarLoader } from "react-spinners";
import { sendEmbedMessage } from "../utils/api";

export const CustomEmbedContent = () => {
  const [author, setAuthor] = useState<string>('');
  const [authorUrl, setAuthorUrl] = useState<string>('');
  const [content, setContent] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [image, setImage] = useState<string>('');
  const [footer, setFooter] = useState<string>('');
  const [footerUrl, setFooterUrl] = useState<string>('');
  const [inputcolor, setColor] = useState("#000000");
  const [channel, setChannel] = useState<string>();
  const [toggled, setToggled] = useState("closed");
  const { guild } = useContext(GuildContext);
  const guildId = (guild && guild.id) || '';
  const { channels, loading, selectedChannel, setSelectedChannel } = useEconomicCalendar(guildId);
  const hexToDecimal = (hex: string) => parseInt(hex, 16);
  const strippedColor = inputcolor.replace("#", "")
  const color = parseInt(strippedColor.padStart(8, "0x"), 16)
  console.log(color + " " + inputcolor)
  const json = {
    'embeds': [{
      title,
      description,
      color,
    }]
  }
  const embedJSON = (json: any) => {
    const jsonString = JSON.stringify(json);
    return jsonString;
  }
  const postEmbedMessage = async () => {
    const jsonString = JSON.stringify(json);
    sendEmbedMessage(channel!, jsonString).then(res => console.log(res));
  };
  return (channels && !loading) ? (
    <Page>
      <Container style={{ width: 'auto', maxWidth: '800px', position: 'absolute', left: '25vw' }}>
        <Grid item xs={6}>
          <div>
            <label htmlFor="channel">Select a Channel</label>
          </div>
          <Select
            name="channelSelect"

            classNamePrefix={"channelSelect"}
            options={channels}
            onChange={(e) => {
              setChannel(e?.id)
            }}
            getOptionLabel={(channels) => `#${channels.name}`}
            getOptionValue={(channels) => channels.id}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                backgroundColor: 'black',
                borderRadius: '5px',
                borderColor: '#BFA874',
                color: 'white',
                width: "300px",
                marginBottom: "20px"
              }),
              menu: (base) => ({
                ...base,
                // override border radius to match the box
                borderColor: '#BFA874',
                borderRadius: 0,
                // kill the gap
                marginTop: 0,
                backgroundColor: "black",
                pointerEvents: "all",
                boxShadow: "#BFA874 0px 1px 4px"
              }),
            }}
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                text: 'black',
                font: 'black',
                primary25: '#393939',
                primary75: '#BFA874',
                primary50: 'black',
                primary: '#BFA874',
                neutral80: 'white',
                color: 'white',
              }
            })}
          />
          <div>
            <label htmlFor="title">Message Content</label>
          </div>
          <TextArea id="content" style={{ marginTop: "10px", height: "30px", width: "300px", resize: "vertical", overflow: "hidden", padding: "5px 5px" }} onChange={e => setContent(e.target.value)} />
          <div style={{ marginTop: "50px" }} />
          <div>
            <label htmlFor="author-img">Author Image</label>
          </div>
          <TextArea id="author-img" style={{ marginTop: "10px", height: "30px", width: "300px", resize: "none", overflow: "hidden", padding: "5px 5px" }} onChange={e => setAuthorUrl(e.target.value)} />
          <div>
            <label htmlFor="author">Author</label>
          </div>
          <TextArea id="author" style={{ marginTop: "10px", height: "30px", width: "300px", resize: "none", overflow: "hidden", padding: "5px 5px" }} onChange={e => setAuthor(e.target.value)} />
          <div>
            <label htmlFor="title">Title</label>
          </div>
          <TextArea id="title" style={{ marginTop: "10px", height: "30px", width: "300px", resize: "vertical", overflow: "hidden", padding: "5px 5px" }} onChange={e => setTitle(e.target.value)} />
          <div>
            <label htmlFor="description">Description</label>
          </div>
          <TextArea id="description" style={{ marginTop: "10px", height: "100px", width: "300px", resize: "vertical", overflow: "hidden", padding: "5px 5px" }} onChange={e => setDescription(e.target.value)} />
          <div>
            <label htmlFor="image">Image</label>
          </div>
          <TextArea id="image" style={{ marginTop: "10px", height: "30px", width: "300px", resize: "none", overflow: "hidden", padding: "5px 5px" }} onChange={e => setImage(e.target.value)} />
          <div>
            <label htmlFor="footer-img">Footer Url</label>
          </div>
          <TextArea id="footer-img" style={{ marginTop: "10px", height: "30px", width: "300px", resize: "none", overflow: "hidden", padding: "5px 5px" }} onChange={e => setFooterUrl(e.target.value)} />
          <div>
            <label htmlFor="footer">Footer</label>
          </div>
          <TextArea id="footer" style={{ marginTop: "10px", height: "30px", width: "300px", resize: "none", overflow: "hidden", padding: "5px 5px" }} onChange={e => setFooter(e.target.value)} />
          <div style={{ marginBottom: "10px" }}>
            <label htmlFor="color">Color</label>
          </div>
          <HexColorInput color={inputcolor} onChange={setColor} />
          <HexColorPicker color={inputcolor} onChange={setColor} />
        </Grid>
        <Grid item xs={6}>
          <Button style={{ verticalAlign: "middle", padding: "0 0", marginTop: "25px", marginBottom: "25px", width: "300px", height: "50px", textAlign: "center" }} variant="primary" onClick={postEmbedMessage} >Send Embed</Button>
        </Grid>
      </Container>
    </Page>
  ) : (<Flex justifyContent="center" alignItems="center"><BarLoader color="white" /></Flex>);
}