import axios from 'axios';
import { GuildConfigType, PartialGuild, PartialRoles, User } from './types';

const API_URL = 'https://asebot.com/dev/api';
//const API_URL = 'http://127.0.0.1:3003/dev/api';

export const getAuthStatus = async () =>
  await axios.get<User>(`${API_URL}/auth/status`, { withCredentials: true });

export const getMutualGuilds = async () =>
  await axios.get<PartialGuild[]>(`${API_URL}/discord/guilds`, { withCredentials: true });

export const getUnaddedGuilds = async () =>
  await axios.get<PartialGuild[]>(`${API_URL}/discord/unaddedguilds`, { withCredentials: true });

export const getGuildConfig = async (guildId: string) =>
  await axios.get<GuildConfigType>(`${API_URL}/guilds/config/${guildId}`, { withCredentials: true });

export const getGuildRoles = async (guildId: string) =>
  await axios.get<PartialRoles[]>(`${API_URL}/discord/guilds/${guildId}/roles`, { withCredentials: true });

export const updateGuildPrefix = async (guildId: string, prefix: string) =>
  await axios.post(
    `${API_URL}/guilds/${guildId}/config/prefix`,
    {
      prefix,
    },
    { withCredentials: true }
  );

export const updateWelcomeChannelId = async (guildId: string, channelId: string) => await axios.post(`${API_URL}/guilds/${guildId}/config/welcome`, {
  channelId,
}, { withCredentials: true });

export const updateEconomicCalendarChannelId = async (guildId: string, channelId: string) => await axios.post(`${API_URL}/guilds/${guildId}/config/calendar`, {
  channelId,
}, { withCredentials: true });

export const updateEconomicCalendarChannelEnable = async (guildId: string, calendarEnable: string) => await axios.post(`${API_URL}/guilds/${guildId}/config/calendar/enable`, {
  calendarEnable,
}, { withCredentials: true });

export const updateNewsEnable = async (guildId: string, type: string, enable: string) => await axios.post(`${API_URL}/guilds/${guildId}/config/news/enable`, {
  type, enable,
}, { withCredentials: true });

export const updateNewsChannel = async (guildId: string, type: string, channelId: string) => await axios.post(`${API_URL}/guilds/${guildId}/config/news`, {
  type, channelId,
}, { withCredentials: true });


export const getGuildChannels = async (guildId: string) => await axios.get(`${API_URL}/discord/guilds/${guildId}/channels`, { withCredentials: true });

export const updateEconomicCalendarTime = async (guildId: string, calendarTime: Date) => await axios.post(`${API_URL}/guilds/${guildId}/config/calendar/time`, {
  calendarTime,
}, { withCredentials: true });

export const updateEconomicCalendarTimezone = async (guildId: string, calendarTimezone: string) => await axios.post(`${API_URL}/guilds/${guildId}/config/calendar/timezone`, {
  calendarTimezone,
}, { withCredentials: true });

export const updateRoleMention = async (guildId: string, roleMentionID: string | null) => await axios.post(`${API_URL}/guilds/${guildId}/config/calendar/role`, {
  roleMentionID,
}, { withCredentials: true });

export const updateRoleMentionEnable = async (guildId: string, roleEnable: string) => await axios.post(`${API_URL}/guilds/${guildId}/config/calendar/role/enable`, {
  roleEnable,
}, { withCredentials: true });

export const sendEmbedMessage = async (channelId: string, postData: string) =>
  await axios.post(`${API_URL}/guilds/channel/${channelId}/message`, { postData }, { withCredentials: true }).then(res => console.log(res)).catch(err => console.log(err));

// export const createPaymentIntent = async (id: number, customerID: string) =>
//   await axios.post(`${API_URL}/payment-intents/create/${id}`, { id, customerID }, { withCredentials: true })

export const updatePremiumStatus = async (premium: boolean) =>
  await axios.put(`${API_URL}/user/premium/status`, { premium }, { withCredentials: true });

export const getEarningsData = async (user: User) =>
  await axios.get(`${API_URL}/financial/earnings-calendar?apikey=${user.api_key}`, {
    withCredentials: true
  });

export const getNewEarningsData = async (user: User) =>
  await axios.get(`${API_URL}/financial/volume-traded?apikey=${user.api_key}`, {
    withCredentials: true
  });

// export const createCheckout = async (priceID: string, guild: string) =>
//   await axios.get(`${API_URL}/payment-intents/checkout/${priceID}/${guild}`)