import { MouseEventHandler, useContext, useState } from "react";
import { getIconURL } from "../utils/helpers";
import { GuildMenuItemStyle } from "../utils/styles";
import { PartialGuild } from "../utils/types";
import { GuildContext } from "../utils/context/GuildContext";
import { useNavigate } from "react-router-dom";
const GrayCircle = require("../assets/GrayCircle.png")
type Props = {
  guild: PartialGuild;
};

export const GuildMenuItem = ({ guild }: Props) => {
  const navigate = useNavigate();
  const { updateGuild } = useContext(GuildContext);
  const handleClick = async (guild: PartialGuild) => {
    try {
      updateGuild(guild);
      const id = guild.id;
      id && navigate(`/dashboard/${id}`);
    } catch (err) {
      console.log(err);
    }

  };
  return (
    <GuildMenuItemStyle onClick={() => {
      handleClick(guild);
    }} >
      {guild.icon !== null ? (<img
        src={getIconURL(guild)}
        alt={guild.name}
        width={50}
        style={{ borderRadius: '50%', border: '2px solid #BFA874' }}
      />) : (<img
        src={GrayCircle}
        alt={guild.name}
        width={50}
        style={{ borderRadius: '50%', border: '2px solid #BFA874' }}
      />)}
      <p>{guild.name}</p>
    </GuildMenuItemStyle>
  );
}
