import { useContext } from "react";
import { useParams } from "react-router-dom";
import { updateGuildPrefix } from "../utils/api";
import { GuildContext } from "../utils/context/GuildContext";
import { EarningsData, getEarningsCalendarData } from "../utils/fetchEconomicReport";
import { useGuildConfig } from "../utils/hooks/useFetchGuildConfig";
import { ERPage, Page } from "../utils/styles";
import { EarningsCalendarContent } from "./EarningsCalendarContent";
import { GuildPrefixPage } from "./GuildPrefixPage";
import { WelcomeMessagePage } from "./WelcomeMessagePage";

export const EarningsReport = () => {
  const { id } = useParams();
  const util = id;
  return (
    <ERPage style={{ padding: "0px" }}>
      <EarningsCalendarContent />
    </ERPage>)
};
