import styled from 'styled-components';

export const Page = styled.div`
color: white;
textAlign: left;
padding-bottom: 120px;
margin-top: 50px;
min-height: calc(100vh - 365px )
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  justify-items:center;
`;

export const GridContainer = styled.div`
  display: grid;
  margin-left: 15px; 
  width: 1500px;
`;
export const GridRow = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  `;

export const AffiliateGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 10px;
  justify-items:center;
`;

export const SubscriptionGrid = styled.div`
  border: 1px solid #BFA874;
  border-radius: 50px;
  margin: 10px;
  display: grid;
  grid-template-columns: repeat(auto-fit, 500px);
  place-items: center;
  width: auto;
  max-width: 1000px;
  background-color: black;
`;

export const Spacer = styled.div`
padding-top: 10px;
padding-bottom; 10px;
`;
export const BodySpacer = styled.div`
padding-top: 20px;
padding-bottom; 20px;
`;

export const RouteHeader = styled.h1`
fontSize: 30px;
display: flex;
justify-content: center;
text-decoration: underline;
`;
export const TitleHeader = styled.h1`
font-size: 40px;
text-decoration: underline;
`;

export const Body = styled.p`
font-size: 20px;
margin-bottom: 20px;
color: #BFA874;
`;

export const VideoPage = styled.div`
height: 100vh;
width: 100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
object-fit: contain;
`;

export const Video = styled.video`
object-fit: cover;
width: 100%;
height: 100%;
position: fixed;
z-index: -1;
`;

export const Overlay = styled.div`
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const Section = styled.section`
display: grid;
min-height:  100vh;
@media screen and (max-width: 1050px) {
    display: flex;
    flex-direction: column;
    place-items: center;
    height: auto;
  }
`

export const HomePageContainer = styled.div`
overflow-x: hidden;
overflow-y: visible;
color: #BFA874;
scroll-behavior: smooth;
@media screen and (max-width: 1100px) {
  display: none; 
}
`
// display: grid;
// justify-content: center; 
// text-align: center;
// align-items: center;
// grid-column: 1;


export const PricingTableContainer = styled.div`
display: grid;
justify-items: center; 
align-items: center;
// grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
width: auto;
max-width: 100vw;
min-height: 350px;
// row-gap: 20px;
`;

export const PricingTableMobileContainer = styled.div`
display: flex;
flex-direction: column;
gap: 10px;
justify-items: center; 
width: auto;
max-width: 100vw;
min-height: 350px;
margin-bottom: 50px;
`;

export const PricingTableElement = styled.div`
border: 1px solid #BFA874;
display: grid;
text-align: center;
background: linear-gradient(to top, black 0%, black 82%, #BFA874 82%, #BFA874 100%);
color: black;
align-items: center;
justify-items: center;
width: 250px;
height: 350px;
border-radius: 5px;
grid-template-rows: 1fr 1fr 2fr 1fr;
position: relative;
`

export const PricingTableTitle = styled.h1`
margin-top: 0;
margin-bottom: 0;
color: black;
`
export const PricingTablePrice = styled.h2`
color: white;
margin-top: 0;
margin-bottom: 0;
padding: 0px;
`;

export const PricingTableDescriptionItem = styled.h3`
color: #BFA874;
margin-top: 0;
margin-bottom: 0;
padding: 0px;
`;

export const PricingTableDescriptionContainer = styled.div`
display: grid;
text-align: center; 
align-items: center; 
justify-items: center;
margin-bottom: 50px;
margin-top: 50px; 
`

export const PricingTableButton = styled.button`
background-color: #BFA874;
height: 40px;
width: 100px;
color: black;
border-style: solid;
border-radius: 5px;
font-weight: bold;
`;


export const MainButton = styled.div`
  align-items: center;
  display: flex;
  width: 350px;
  justify-content: space-between;
  background-color: #000000;
  padding: 4px 50px;
  box-sizing: border-box;
  border-radius: 50px;
  border: 1px solid #BFA874;
  margin: 10px 0;
  box-shadow: 0px 1px 3px 0px #BFA874;
`;
export const DiscordButton = styled.button`
  align-items: center;
  display: flex;
  cursor: pointer;
  @media screen and (max-width: 1100px) {
    display: none;
  }
  width: auto;
  height: 50px;
  justify-content: space-between;
  background-color: #000000;
  padding: 4px 25px;
  box-sizing: border-box;
  border-radius: 50px;
  border: 1px solid #BFA874;
  align-content: center;
  margin-top: 20px;
  margin-right: 20px;
  color: #BFA874;
  box-shadow: 0px 1px 3px 0px #BFA874;
`;
export const DiscordMobileButton = styled.div`
  align-items: center;
  display: flex;
  width: auto;
  height: 50px;
  justify-content: space-between;
  background-color: #000000;
  padding: 4px 25px;
  box-sizing: border-box;
  border-radius: 50px;
  border: 1px solid #BFA874;
  align-content: center;
  margin-top: 20px;
  margin-right: 20px;
  color: #BFA874;
  box-shadow: 0px 1px 3px 0px #BFA874;
`;

export const AffiliateButton = styled.div`
  align-items: center;
  display: flex;
  width: auto;
  height: 50px;
  justify-content: space-between;
  background-color: #BFA874;
  padding: 4px 25px;
  box-sizing: border-box;
  border-radius: 50px;
  align-content: center;
  margin-top: 20px;
  margin-right: 20px;
  color: #000000;
  box-shadow: 0px 1px 3px 0px #BFA874;
`;

export const Panels = styled.section`
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  padding: 0;
  overflow: hidden;
  background-color: #ddd;
`;

export const PaymentSection = styled.section`
display: grid;
place-items: center;
min-height: calc(100vh - 200px);
`

export const PaymentButton = styled.div`
  align-items: center;
  display: flex;
  width: 350px;
  justify-content: space-between;
  background-color: #000000;
  padding: 4px 50px;
  box-sizing: border-box;
  border-radius: 50px;
  border: 1px solid #BFA874;
  margin: 10px 0;
  box-shadow: 0px 1px 3px 0px #BFA874;
`;
export const PaymentContainer = styled.div`
overflow: hidden;
display: grid;
justify-content: center; 
text-align: center;
align-items: center;
grid-column: 1;
`

export const AppBarStyle = styled.header`
  display: flex;
  align-items: center;
  padding: 15px 35px;
  box-sizing: border- box;
  border-bottom: 1px solid #BFA87421;
  justify-content: space-between;
  maxWidth: 100%;
  background-color: #090909;
`;

export const Title = styled.p`
  font-size: 22px;
`;
export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;